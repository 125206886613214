import React, { useContext, useEffect, useState } from "react";
import { getMercureTopics } from "../../../../utils/getMercureTopics";
import { browsers, mercureTimeOut, mercureUrl, responseStatus, standardErrorsWay } from "../../../../utils/consts";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../../../App";
import { StyledBadgeNotification } from "../styledSidebar";
import { parseAxiosError } from "../../../../utils/response";

const FeedbackCounter = ({ linkTitle, className }) => {

  const { user } = useContext(AuthContext);

  const [counter, setCounter] = useState(null);
  const [cookies, setCookie] = useCookies(["mercureAuthorization"]);

  const fetchNewFeedbacksCounter = () => {
    if (linkTitle === "Feedbacks") {
      axios.get("/api/new-feedbacks-count",
        userAuthenticationConfig()).then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setCounter(response.data);
        }
      }).catch(error => {
        parseAxiosError(eval(standardErrorsWay));
      });
    }
  };

  useEffect(() => {
    fetchNewFeedbacksCounter();
  }, []);

  let esSideBarNewFeedbacksCounter = null;
  let mercureSideBarNewFeedbacksCounterTime = null;

  const mercureSideBarNewFeedbacksCounter = () => {
    clearTimeout(mercureSideBarNewFeedbacksCounterTime);

    mercureSideBarNewFeedbacksCounterTime = setTimeout(() => {
      esSideBarNewFeedbacksCounter.close();
      esSideBarNewFeedbacksCounter = mercureSideBarNewFeedbacksCounter();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("new-feedbacks-counter", [{ id: "" }]),
      { path: "/" });

    esSideBarNewFeedbacksCounter = new EventSource(mercureUrl, { withCredentials: true });

    esSideBarNewFeedbacksCounter.onmessage = () => {
      fetchNewFeedbacksCounter();
    };

    esSideBarNewFeedbacksCounter.onerror = () => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esSideBarNewFeedbacksCounter = mercureSideBarNewFeedbacksCounter();
    };

    return esSideBarNewFeedbacksCounter;
  };

  useEffect(() => {
    esSideBarNewFeedbacksCounter = mercureSideBarNewFeedbacksCounter();

    return () => {
      esSideBarNewFeedbacksCounter.close();
      clearTimeout(mercureSideBarNewFeedbacksCounterTime);
    };

  }, []);

  if (!counter || counter === 0) {
    return <></>;
  }

  return (
    <StyledBadgeNotification className={className}>
      {counter}
    </StyledBadgeNotification>
  );
};

export default FeedbackCounter;
