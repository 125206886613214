import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import Select, { Option } from "rc-select";
import { roles } from "../../../utils/consts";
import { userRole } from "../../../utils/userRole";

const LogsRoleSelect = ({ role, setRole }) => {

  return (
    <StyledSelect>
      <StyledSelectLabel>Role</StyledSelectLabel>
      <Select
        className="custom-select"
        name="category"
        value={role ?? null}
        defaultValue={null}
        onChange={(value) => setRole(value)}
        getPopupContainer={() => document.getElementById("filter")}
      >
        <Option value={null}>
          <div className="option-select-item">
            {"All"}
          </div>
        </Option>
        <Option value={roles.ADMIN}>
          <div className="option-select-item">
            {userRole(roles.ADMIN)}
          </div>
        </Option>
        <Option value={roles.MANAGER}>
          <div className="option-select-item">
            {userRole(roles.MANAGER)}
          </div>
        </Option>
        <Option value={roles.OWNER}>
          <div className="option-select-item">
            {userRole(roles.OWNER)}
          </div>
        </Option>
        <Option value={roles.SYSTEM}>
          <div className="option-select-item">
            {userRole(roles.SYSTEM)}
          </div>
        </Option>
      </Select>
    </StyledSelect>

  );
};

export default LogsRoleSelect;