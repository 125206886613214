import React, { lazy } from "react";

const EmailConfirm = lazy(() => import("../pages/emailConfirm/EmailConfirmPage"));
const ForgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPasswordPage"));
const ChangePassword = lazy(() => import("../pages/changePassword/ChangePasswordPage"));
const NotFoundPage = lazy(() => import("../pages/pageNotFound/NotFoundPage"));

const HomePage = lazy(() => import("../pages/home/HomePage"));
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const RegistrationPage = lazy(() => import("../pages/registration/RegistrationPage"));
const PublicTariffsAndSubscriptionsPage = lazy(() => import("../pages/tariffsAndSubscriptions/public/PublicTariffsAndSubscriptionsPage"));
const TelegramPublicManualPage = lazy(() => import("../pages/manualConnectPlugins/TelegramPublicManualPage"));
const ViberPublicManualPage = lazy(() => import("../pages/manualConnectPlugins/ViberPublicManualPage"));
const WebPublicManualPage = lazy(() => import("../pages/manualConnectPlugins/WebPublicManualPage"));
const CardVerificationManual = lazy(() => import("../pages/cardVerificationManual/CardVerificationManual"));
const SamplePublicManualPage = lazy(() => import("../components/elements/samplePublicManualPage/SamplePublicManualPage"));

const routes = [
  {
    title: "Home page",
    path: "/:id(.relationToken=[a-z-_0-9]*)?",
    exact: true,
    component: HomePage
  },
  {
    title: "Login page",
    path: "/login",
    exact: false,
    component: LoginPage
  },
  {
    title: "Registration page",
    path: "/registration",
    exact: false,
    component: RegistrationPage
  },
  {
    title: "Email Confirm",
    path: "/email-confirm/:token?",
    component: EmailConfirm
  },
  {
    title: "Forgot Password",
    path: "/remind",
    component: ForgotPassword
  },
  {
    title: "Change Password",
    path: "/change-password/:token?",
    component: ChangePassword
  },
  {
    title: "404 Page",
    path: "/page-not-found",
    exact: true,
    component: NotFoundPage
  },
  {
    title: "Tariff & Subscription",
    path: "/tariff-and-subscription",
    exact: false,
    component: PublicTariffsAndSubscriptionsPage
  },
  {
    title: "Manual connect telegram",
    path: "/manual-connect-telegram",
    exact: false,
    component: TelegramPublicManualPage
  },
  {
    title: "Manual connect viber",
    path: "/manual-connect-viber",
    exact: false,
    component: ViberPublicManualPage
  },
  {
    title: "Manual connect web",
    path: "/manual-connect-web",
    exact: false,
    component: WebPublicManualPage
  },
  {
    title: "Card verification manual",
    path: "/card-verification",
    exact: false,
    component: CardVerificationManual
  },
  {
    title: "Automation and optimization",
    path: "/automation-and-optimization",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Feedback from the client and control of correspondence",
    path: "/feedback-and-control-of-correspondence",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Access from any device at any time",
    path: "/access-from-any-device-at-any-time",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Transparent and understandable rates",
    path: "/transparent-and-understandable-rates",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Integration with other messengers",
    path: "/integration-with-other-messengers",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Ability to communicate with several users at once",
    path: "/chat-with-several-users-at-once",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "How will it look on your site?",
    path: "/how-look-widget",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Select online chat service provider",
    path: "/select-online-chat-service-provider",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Add an online chat script and set up a personal account",
    path: "/add-online-chat-script",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Select online chat service provider",
    path: "/add-all-necessary-social-networks",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Create an account group for managers.",
    path: "/create-account-group-managers",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Contact with customers in the most profitable",
    path: "/contact-with-customers-profitable",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "About us",
    path: "/about-us",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "User Agreement",
    path: "/user-agreement",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Privacy Policy",
    path: "/privacy-policy",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Blog",
    path: "/blog",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "Help",
    path: "/help",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "WhatsApp",
    path: "/whatsApp",
    exact: false,
    component: SamplePublicManualPage
  },
  {
    title: "CookiesPolicy",
    path: "/cookies-policy",
    exact: false,
    component: SamplePublicManualPage
  }
];

export default routes;