import { roles } from "./consts";

const userRole = (role) => {
  switch (role) {
    case roles.MANAGER: {
      return "Manager";
    }
    case roles.ADMIN: {
      return "Admin";
    }
    case roles.OWNER: {
      return "Owner";
    }
    case roles.SYSTEM: {
      return "System";
    }
  }
};

export { userRole };