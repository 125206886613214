import React from "react";
import HelmetContainer from "../../elements/helmetContainer/HelmetContainer";
import Tabs, { TabPane } from "rc-tabs";
import { StyledLogsWrapper } from "../styledLogs";
import LogsContainer from "./LogsContainer";

const LogsWrapper = () => {

  return (
    <StyledLogsWrapper>
      <HelmetContainer title="Logs">
        <Tabs
          defaultActiveKey="project"
          tabPosition="top"
          className="default-tabs default-tabs-top"
        >
          <TabPane
            tab="Logs"
            key="log"
          >
            <LogsContainer />
          </TabPane>
        </Tabs>
      </HelmetContainer>
    </StyledLogsWrapper>
  );
};

export default LogsWrapper;