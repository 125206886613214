import styled from "styled-components";
import { StyledContainer } from "../styles/styledContainer";

export const StyledLogsWrapper = styled(StyledContainer)`
  padding: 0;

  .default-tabs-top {
    .rc-tabs-nav-list, .rc-tabs-tabpane {
      padding: 0 15px;
    }
  }
  
  .logs-table {
    &__row {
      &:nth-child(2n) {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    @media screen and (min-width: 992px) {
      &__head, &__row {
        grid-template-columns: minmax(225px, 1fr) 80px 80px 2fr 160px 160px;
      }
    }
  }
`;