// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_NOT_FOUND: 404,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409
};

const standardErrorsWay = "error.response.data.data.errors";

const canceledStatus = "canceled";

const exceptionPath = [
  "/",
  "/tariff-and-subscription",
  "/manual-connect-telegram",
  "/manual-connect-viber",
  "/manual-connect-web",
  "/page-not-found",
  "/automation-and-optimization",
  "/feedback-and-control-of-correspondence",
  "/access-from-any-device-at-any-time",
  "/transparent-and-understandable-rates",
  "/integration-with-other-messengers",
  "/chat-with-several-users-at-once",
  "/how-look-widget",
  "/select-online-chat-service-provider",
  "/add-online-chat-script",
  "/add-all-necessary-social-networks",
  "/create-account-group-managers",
  "/contact-with-customers-profitable",
  "/about-us",
  "/user-agreement",
  "/privacy-policy",
  "/card-verification",
  "/blog",
  "/help",
  "/whatsApp",
  "/cookies-policy"
];
const creditCardStatuses = {
  VERIFIED: "VERIFIED",
  NOT_VERIFIED: "NOT_VERIFIED",
  CANCELED: "CANCELED",
  PAST_DUE_DATE: "PAST_DUE_DATE"
};

const roles = {
  ADMIN: "ROLE_ADMIN",
  MANAGER: "ROLE_MANAGER",
  SYSTEM: "ROLE_SYSTEM",
  OWNER: "ROLE_OWNER"
};

const arrayOperation = {
  FIRST: 0
};

const defaultStatusFilterForChats = ["NEW", "IN_PROGRESS"];

const defaultStatusFilterForCorporateChats = ["CORPORATE"];

const examinationMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;

const timeoutOnHookNotification = 2500;

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+((\\.[a-z-])*(\\.[a-z]{2,}){1,5})$",
  ALPHABET: /([A-Z])/g,
  REGEX_STANDARD_STATUS: /_/g,
  REGEX_NUMBER: /^\d+$/,
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-я0-9_ґіїэєёышщъухротьюА-ЯҐІЇЄЁЫ]+$",
  DIGITS_PATTERN: "\\d+(?:[.,]\\d+)?",
  TIME: "^(([01]?[0-9]|2[0-3]):[0-5][0-9]|24:00)$",
  URL_IN_CHAT: /(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|ftps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~])[a-zA-Z\d!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,50}$/,
  ONE_LETTER_AT_LEAST: /^.*[A-Za-zА-Яа-яЁё]{1,}.*$/,
  EXTRA_SPACES: / +/g,
  MONGO_DATE_FORMAT: /^(?:\d{4})-(?:(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[1-2]\d|3[0-1])|(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[1-2]\d|3[0-1])|(?:0?[1-9]|[1-2]\d|3[0-1]))$/
};

const browsers = {
  FIREFOX: "Firefox"
};

const userAgentType = {
  FIREFOX: "firefox"
};

const twoFa = {
  REQUIRED_LENGTH: 6
};

const maxNameLength = 30;

const maxEmailLength = 100;

const zeroValue = 0;

const firstValue = 1;

const minLength = 10;

const lastMessageIdForScroll = 50;

const maxMessageLength = 5000;

const maxTelegramMessageLength = 4096;

const maxFeedbackLength = 3600;

const maxSizeFiles = 15728640;

const maxSizeFilesMegabyte = 15;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const mercureTimeOut = 55000;

const audioAlertPath = "alert/alert.wav";

const timeConsts = {
  DAY: 86400000,
  NORMALIZER: 1000,
  MONTH_IN_A_YEAR: 12,
  DAYS_IN_MONTH: 30
};

const logs_levels = {
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const inputTypes = {
  NUMBER: "number"
};

const statusCreateProject = {
  PROJECT: {
    project: "process",
    categories: "wait",
    templates: "wait",
    answersTemplates: "wait",
    settings: "wait"
  },
  CATEGORIES: {
    project: "finish",
    categories: "process",
    templates: "wait",
    answersTemplates: "wait",
    settings: "wait"
  },
  TEMPLATES: {
    project: "finish",
    categories: "finish",
    templates: "process",
    answersTemplates: "wait",
    settings: "wait"
  },
  ANSWERS_TEMPLATES: {
    project: "finish",
    categories: "finish",
    templates: "finish",
    answersTemplates: "process",
    settings: "wait"
  },
  SETTINGS: {
    project: "finish",
    categories: "finish",
    templates: "finish",
    answersTemplates: "finish",
    settings: "process"
  }
};

const statusErrorProject = {
  project: "project",
  categories: "categories",
  templates: "templates",
  answerTemplates: "answerTemplates",
  settings: "settings",
  chatForm: "chatForm",
  subscription: "subscription"
};

const projectFieldsValidation = {
  emptyString: "",
  countOfSymbolsMin: 3,
  countOfSymbolsMax: 255,
  countFormFields: 66,
  slowMode: 2
};

const chatMessageStatuses = {
  new: "new",
  read: "read"
};

const positions = {
  LEFT_CENTER: "left-center",
  LEFT_BOTTOM: "left-bottom",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_RIGHT: "bottom-right",
  RIGHT_BOTTOM: "right-bottom",
  RIGHT_CENTER: "right-center"
};

const colors = {
  RED: "red",
  ORANGE: "orange",
  YELLOW: "yellow",
  GREEN: "green",
  BLUE: "blue",
  PURPLE: "purple",
  LAVENDER: "lavender",
  TURQUOISE: "turquoise",
  DARK_YELLOW: "dark-yellow",
  DARK_GREEN: "dark-green",
  DEFAULT: "origin"
};

const weekend = {
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
  DEFAULT: null
};

const chatMessageType = {
  MESSAGE: "message",
  MEDIA_MESSAGE: "media_message",
  SYSTEM: "system",
  DELETED: "deleted",
  NOTE: "note"
};

const chatStatus = {
  NEW: "new",
  FINISHED: "finished",
  IN_PROGRESS: "inProgress"
};

const chatStatusSelect = {
  NEW: "New",
  IN_PROGRESS: "In Progress",
  FINISHED: "Finished",
  BANNED: "Banned"
};

const subscriptionsType = {
  TRIAL_TEMPLATE: "trial_template",
  CUSTOM_TEMPLATE: "custom_template",
  INDIVIDUAL_TEMPLATE: "individual_template",
  TRIAL: "trial",
  CUSTOM: "custom",
  INDIVIDUAL: "individual"
};

const noSubscriptionStatus = "noSubscription";

const domainExistsInHistoryAndDisableTrialSubscriptionStatus = "domainExistsInHistoryAndDisableTrialSubscription";

const avatarFormatErrors = "Wrong file extension. Acceptable types: jpeg, png, gif, heif.";

const tariffsType = {
  DEFAULT: "default",
  PERSONAL: "personal"
};

const balanceSettings = {
  DIGITS_AFTER_COMA: 2
};

const subscriptionsSettings = {
  IS_FREE: "0",
  IS_UNLIMITED: "unlimited",
  IS_CONFIGURABLE: "configurable"
};

const userChanges = {
  LOGOUT: "logout",
  RESET_TWO_FA: "reset_two_fa"
};

const imagesConsts = {
  MAX_SIZE: 5242880,
  MAX_COUNT: 5,
  WHATSAPP_IMAGES_COUNT: 1
};

const fileExtensions = {
  JPEG: ".jpeg",
  JPG: ".jpg",
  PNG: ".png",
  GIF: ".gif",
  HEIC: ".heic"
};

const fileTypes = {
  JPEG: "image/jpeg",
  PNG: "image/png",
  GIF: "image/gif",
  HEIF: "image/heif"
};

const acceptableFileTypes = [
  fileTypes.JPEG,
  fileTypes.PNG,
  fileTypes.GIF,
  fileTypes.HEIF
];

const creditAcceptableCardFileTypes = [
  fileTypes.JPEG,
  fileTypes.PNG,
  fileTypes.HEIF
];

const settingsConsts = {
  TRUE_NUM: "1",
  FALSE_NUM: "0",
  TIME_ZONE_MIN: -12,
  TIME_ZONE_MAX: 14,
  AUDIO_VOLUME: 0.3,
  DEFAULT_TIME_ZONE: 0
};

const projectSettingsNames = {
  AUDIO_ALERT: "audio_alert",
  VIEW_DELAY: "view_delay",
  POSITION: "position",
  SLOW_MODE: "slow_mode",
  COLOR: "color",
  FONT: "font",
  HEADER_TEXT: "header_text",
  WORKING_TIME_START: "working_time_start",
  WORKING_TIME_END: "working_time_end",
  TIME_ZONE: "time_zone",
  WEEKEND: "weekend",
  MANAGER_OFFLINE_TIME: "manager_offline_time"
};

const projectSettingsDefaultValues = [
  {
    name: "slow_mode",
    value: "3"
  },
  {
    name: "position",
    value: "bottom-right"
  },
  {
    name: "view_delay",
    value: "0"
  },
  {
    name: "color",
    value: "origin"
  },
  {
    name: "font",
    value: "text-me-chat"
  },
  {
    name: "header_text",
    value: "Online chat"
  },
  {
    name: "audio_alert",
    value: "1"
  },
  {
    name: "working_time_start",
    value: "09:00"
  },
  {
    name: "working_time_end",
    value: "18:00"
  },
  {
    name: "time_zone",
    value: "0"
  },
  {
    name: "weekend",
    value: ""
  },
  {
    name: "manager_offline_time",
    value: "30"
  }
];

const chatFormDefaultValues = [
  {
    name: "firstname",
    isRequired: true,
    isActive: true,
    isStandard: true
  },
  {
    name: "lastname",
    isRequired: true,
    isActive: true,
    isStandard: true
  },
  {
    name: "email",
    isRequired: true,
    isActive: true,
    isStandard: true
  }
];

const timeZones = {
  [-12]: "BIT (UTC-12:00)",
  [-11]: "NUT (UTC-11:00)",
  [-10]: "HST (UTC-10:00)",
  [-9]: "AKST (UTC-9:00)",
  [-8]: "PST (UTC-8:00)",
  [-7]: "PDT (UTC-7:00)",
  [-6]: "CST (UTC-6:00)",
  [-5]: "EASST (UTC-5:00)",
  [-4]: "AST (UTC-4:00)",
  [-3]: "ADT (UTC-3:00)",
  [-2]: "GST (UTC-2:00)",
  [-1]: "EGT (UTC-1:00)",
  0: "GMT (UTC)",
  1: "CET (UTC+1:00)",
  2: "EET (UTC+2:00)",
  3: "EAT (UTC+3:00)",
  4: "AZT (UTC+4:00)",
  5: "TFT (UTC+5:00)",
  6: "BIOT (UTC+6:00)",
  7: "WIT (UTC+7:00)",
  8: "WST (UTC+8:00)",
  9: "EIT (UTC+9:00)",
  10: "AEST (UTC+10:00)",
  11: "MIST (UTC+11:00)",
  12: "NZST (UTC+12:00)",
  13: "NZDT (UTC+13:00)",
  14: "LINT (UTC+14:00)"
};

const transactionStatus = {
  FAIL: "FAIL",
  INIT: "init",
  IN_PROGRESS: "inProgress",
  NEW: "NEW",
  PARTIALLY_PROCESSED: "partiallyProcessed",
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  CANCELED: "CANCELED",
  WAITING: "waiting",
  NOT_PAID: "notPaid",
  CARD_VERIFICATION: "CARD_VERIFICATION"
};

const directions = {
  PAYMENT: "payment",
  PAYOUT: "payout"
};

const paymentSystems = {
  CRYPTO_ACCEPTCOIN: "Acceptcoin"
};

const flowDataNames = {
  ACCEPTCOIN_LINK_NAME: "acceptcoinLink",
  GATE_LINK: "link"
};

const userSettings = {
  USER_AUDIO: "user_alert"
};

const feedback = {
  INITIAL_STATE: { firstname: "", lastname: "", email: "", message: "" }
};

const feedbackStatusConst = {
  NOT_VIEWED: "NOT_VIEWED",
  VIEWED: "VIEWED",
  WELL_DONE: "WELL_DONE",
  DELETED: "DELETED"
};

const author = {
  MANAGER: "admin",
  CLIENT: "client"
};

const messageAuthor = {
  MANAGER: "manager",
  CLIENT: "client"
};

const arrayKeys = {
  ZERO: 0,
  FIRST: 1
};

const changedTypeChat = {
  SPAM: "Banned"
};

const chatsType = {
  MY: "My",
  FREE: "Free",
  ALL: "All",
  TAKEN: "Taken",
  SPAM: "Spam"
};

const dialogsType = {
  VIBER: "viber",
  TELEGRAM: "telegram",
  WHATSAPP: "whatsapp",
  CORPORATE: "corporate"
};

const whatsappMessengerFields = {
  WABA_ID: "wabaId",
  WABA_TOKEN: "wabaToken",
  WABA_HOOK_URL: "wabaHookUrl",
  WABA_PHONE_ID: "wabaPhoneId",
  WABA_PHONE_EVENT: "wabaPhoneEvent",
  WABA_PHONE_CURRENT_LIMIT: "wabaPhoneCurrentLimit",
  WABA_PHONE_DISPLAY_NUMBER: "wabaPhoneDisplayNumber",
  WABA_PHONE_QUALITY_UPDATE_TIME: "wabaPhoneQualityUpdateTime"
};

const whatsappMessengerFieldsFormat = {
  "wabaId": "Id",
  "wabaToken": "Token",
  "wabaHookUrl": "Webhook URL",
  "wabaPhoneId": "Phone Id",
  "wabaPhoneEvent": "Phone event",
  "wabaPhoneCurrentLimit": "Phone current limit",
  "wabaPhoneDisplayNumber": "Phone display number",
  "wabaPhoneQualityUpdateTime": "Phone quality update time"
};

const soundsTypes = {
  NEW: "new",
  INBOX: "inbox",
  SENT: "sent"
};

const datesMinMax = {
  MIN: new Date("2022-01-01"),
  MAX: new Date(new Date().setDate((new Date()).getDate() + 1))
};

const statistic = {
  dialogs: "dialogsStatistic",
  managers: "managersStatistic",
  archive: "archivedDialogs"
};

const chatType = [
  "Chats",
  "Team Chats"
];

const corporateChatPage = "corporate";

const projectActions = {
  CREATE: "create",
  UPDATE: "update"
};

const projectActionsConfirmationTypes = {
  DISABLE: "disable",
  LEAVE: "leave",
  JOIN: "join",
  COMPLETE: "complete",
  REOPEN: "reopen",
  SHARE_CHAT: "share chat"
};

const projectChangeableFields = {
  SITE_URL: "siteUrl",
  NAME: "name"
};

const fileValidations = {
  SIZE: 5 * 1024 * 1024,
  SIZE_ERROR: "File size should not exceed 5MB!",
  AMOUNT_ERROR: "Max files amount is: 5",
  MAX_AMOUNT: 5,
  EMPTY: 0,
  ONE: 1,
  FORMATS: [
    "image/png",
    "image/jpeg",
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "text/csv",
    "application/xml",
    "text/xml"
  ],
  FORMATS_ERROR: "Only PNG, JPEG, PDF, TXT, DOCX, DOC, XLSX, CSV, and XML files are allowed!"
};

const dialogCompleteConst = {
  COMPLETED: "Chat is completed",
  REOPEN: "Chat is reopen",
  BLOCKED: "Chat is blocked",
  UNBLOCKED: "Chat is unblocked"
};

const googleAuthenticator = {
  APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
  GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=us"
};

const fetchDialogMessageKeys = [
  "left",
  "join",
  "complete",
  "reopen",
  "share chat"
];

export {
  maxSizeFilesMegabyte,
  maxSizeFiles,
  standardErrorsWay,
  exceptionPath,
  canceledStatus,
  examinationMobileDevice,
  lastMessageIdForScroll,
  responseStatus,
  roles,
  regex,
  arrayOperation,
  mercureTopicOrigin,
  mercureUrl,
  minLength,
  timeConsts,
  logs_levels,
  statusCreateProject,
  chatMessageStatuses,
  chatMessageType,
  positions,
  colors,
  weekend,
  chatStatus,
  maxMessageLength,
  maxFeedbackLength,
  subscriptionsType,
  noSubscriptionStatus,
  domainExistsInHistoryAndDisableTrialSubscriptionStatus,
  tariffsType,
  balanceSettings,
  userChanges,
  imagesConsts,
  fileExtensions,
  fileTypes,
  acceptableFileTypes,
  creditAcceptableCardFileTypes,
  settingsConsts,
  transactionStatus,
  directions,
  audioAlertPath,
  projectSettingsNames,
  timeZones,
  inputTypes,
  zeroValue,
  firstValue,
  userSettings,
  feedback,
  feedbackStatusConst,
  author,
  arrayKeys,
  maxNameLength,
  maxEmailLength,
  defaultStatusFilterForChats,
  defaultStatusFilterForCorporateChats,
  chatsType,
  dialogsType,
  whatsappMessengerFieldsFormat,
  whatsappMessengerFields,
  chatStatusSelect,
  subscriptionsSettings,
  soundsTypes,
  datesMinMax,
  chatFormDefaultValues,
  projectSettingsDefaultValues,
  statusErrorProject,
  projectFieldsValidation,
  creditCardStatuses,
  browsers,
  mercureTimeOut,
  statistic,
  chatType,
  projectActions,
  projectActionsConfirmationTypes,
  projectChangeableFields,
  timeoutOnHookNotification,
  corporateChatPage,
  messageAuthor,
  fileValidations,
  avatarFormatErrors,
  dialogCompleteConst,
  maxTelegramMessageLength,
  googleAuthenticator,
  paymentSystems,
  flowDataNames,
  changedTypeChat,
  fetchDialogMessageKeys,
  userAgentType,
  twoFa
};
