import React, { useState } from "react";

const useSettingChat = () => {
  const [settingChatSendMess, setSettingChatSendMess] = useState(localStorage.getItem("sendBtn") || "Enter");

  const handleChangeSettingsSendMess = (v, e) => {
    localStorage.setItem("sendBtn", e.target.value);
    setSettingChatSendMess(e.target.value);
  };

  return {
    settingChatSendMess,
    setSettingChatSendMess,
    handleChangeSettingsSendMess
  };
};

export default useSettingChat;