import React from "react";
import Spinner from "../spinner/Spinner";

import { StyledTotalItems } from "../../styles/styledFilter__NEW";

const FilterTotalItems = ({ paginationInfo }) => {
  return (
    <StyledTotalItems className="chat-filter-block__quantity">
      <p>
        Quantity: {paginationInfo.totalItems === null ?
        <Spinner margin="0 0 0 5px" size="14px" /> : paginationInfo.totalItems}
      </p>
    </StyledTotalItems>
  );
};

export default React.memo(FilterTotalItems);