import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../App";
import { NavLink } from "react-router-dom";
import Drawer from "rc-drawer";
import SidebarLinkList from "./SidebarLinkList";
import eventBus from "../../../utils/eventBus";
import { jewelryRole, nameRole } from "./examinationRoleUser";

import { StyledButton } from "../../styles/styledButton";
import {
  StyledAccount,
  StyledSeparator,
  StyledSidebar,
  StyledSidebarAction,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarWrapper
} from "./styledSidebar";
import Tooltip from "rc-tooltip";
import { roles } from "../../../utils/consts";
import { StyledAuthButtonsHomeHeader } from "../../home/headers/styledHomePageHeaders";

const SidebarContainer = ({ userInfo, fetchUser }) => {

  const { user, authenticated, globalRerender, setSubscriptionDaysToEnd } = useContext(AuthContext);

  const jewelryRoleMemo = useMemo(() => jewelryRole(user?.roles), [user?.roles]);
  const roleName = useMemo(() => nameRole(user?.roles), [user?.roles]);

  const [visible, setVisible] = useState(false);

  const showSidebar = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    fetchUser();
  }, [globalRerender, authenticated]);

  if (!authenticated) {
    return (
      <StyledAuthButtonsHomeHeader>
        <StyledButton
          color="white"
          border="true"
          size="14"
          as={NavLink}
          to="/login"
          className="login-button"
        >
          <span className="login-button__icon icon-account" />
          <span className="login-button__text">Login</span>
        </StyledButton>
        <StyledButton
          color="white"
          size="14"
          as={NavLink}
          to="/registration"
          className="registration-button"
          border="true"
        >
          Sign up free
        </StyledButton>
      </StyledAuthButtonsHomeHeader>
    );
  }

  return (
    <StyledSidebarWrapper>
      <StyledSidebarAction>
        <button
          title="Profile"
          onClick={showSidebar}
        >
          <span className="icon-account" onClick={showSidebar}/>
        </button>
      </StyledSidebarAction>
      <Drawer
        width="320"
        placement="right"
        handler={false}
        open={visible}
        onClose={onClose}
      >
        <StyledSidebar id="sidebar">
          <StyledAccount className="sidebar-account">
            <div
              className="sidebar-account__info user"
            >
              <div className="user__name">
                {userInfo?.firstname} {userInfo?.lastname}
                <Tooltip
                  placement="bottom"
                  overlay={`Hello, you are ${roleName}`}
                  getTooltipContainer={() => document.getElementById("sidebar")}
                >
                  <img
                    src={jewelryRoleMemo}
                    alt="chat-logo"
                  />
                </Tooltip>
              </div>
              <div className="user__email">{user?.email}</div>
              {user?.roles.toString() === roles.OWNER &&
                <div className="user__balance">
                  <NavLink
                    to="/panel/balance"
                    onClick={onClose}
                  >
                    Balance: <span>{Number(userInfo?.balance?.amount).toFixed(2)}</span> USD
                  </NavLink>
                </div>}
            </div>
            <div className="sidebar-account__settings">
              <span className="icon-cancel" onClick={onClose}/>
              {user?.roles.toString() !== roles.ADMIN &&
                <NavLink
                  to="/panel/account"
                  className="settings-link"
                  onClick={onClose}
                >
                  <span className="icon-settings" />
                </NavLink>}
            </div>
          </StyledAccount>
          <SidebarLinkList
            handleChange={onClose}
            role={user?.roles}
            visible={visible}
          />
          <StyledSeparator />
          <StyledSidebarItem>
            <StyledSidebarLink
              as="button"
              className="sidebar-link"
              onClick={() => {
                onClose();
                eventBus.dispatch("logout");
                setSubscriptionDaysToEnd(null);
              }}
            >
              <div className="sidebar-link__icon">
                <span className="icon-sign-out" />
              </div>
              <div className="sidebar-link__title">
                Log out
              </div>
            </StyledSidebarLink>
          </StyledSidebarItem>
        </StyledSidebar>
      </Drawer>
    </StyledSidebarWrapper>
  );
};

export default SidebarContainer;