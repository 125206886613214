import React from "react";
import Spinner from "./Spinner";

import { StyledPageSpinner } from "./styledSpinner";
import { StyledContainer } from "../../styles/styledContainer";

const PageSpinner = () => {

  return (
    <StyledContainer>
      <StyledPageSpinner>
        <Spinner
          color="#9DA6B6"
          display="block"
          size="35px"
        />
      </StyledPageSpinner>
    </StyledContainer>
  );
};

export default PageSpinner;