import { responseStatus } from "./consts";
import { closableNotification } from "../components/notification/ClosableNotification";

const parserArrayFromObject = (response) => {
  return Object.entries(response).map(([key, value]) => {
    return {
      [key]: value
    };
  });
};

const parseAxiosError = (response, setError = null) => {

  if (!response) {
    return;
  }

  if (Array.isArray(response[0])) {

    const parseError = response.flat().toString();
    return closableNotification(parseError, "error");
  }

  if (!Array.isArray(response[0]) && setError) {
    setError({});

    const customResponse = !Array.isArray(response) ? parserArrayFromObject(response) : response;

    let errorsCustom = {};

    customResponse.forEach(elementError => {

      const [key, value] = Object.entries(elementError).flat();

      errorsCustom[key] = value;
    });

    return setError(prev => ({ ...prev, ...errorsCustom }));
  }

  if (!setError && response[0]) {
    const purseClosableErrors = Object.values(response[0]).toString();
    return closableNotification(purseClosableErrors, "error");
  }

};

const checkNotFound = (error, history) => {

  let status = error.response.status;

  if (status === responseStatus.HTTP_NOT_FOUND) {
    return history.replace("/page-not-found");
  }
};

const hideCreditCardSigns = (cardNumber) => {
  let cardNumberFirst = cardNumber.slice(0, 6);
  let cardNumberLast = cardNumber.substr(cardNumber.length - 4);
  return cardNumberFirst + "******" + cardNumberLast;
};

export {
  parseAxiosError,
  checkNotFound,
  hideCreditCardSigns
};