import React from "react";
import { StyledAlertWrapper } from "./styledAlert";

const AlertMessage = ({ message, type, mb, mt, className, center, border, maxWidth }) => {

  return (
    <StyledAlertWrapper
      maxWidth={maxWidth}
      center={center}
      className={className}
      type={type}
      mt={mt}
      mb={mb}
      border={border}
    >
      <span>{message}</span>
    </StyledAlertWrapper>
  );
};

export default AlertMessage;