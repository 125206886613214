/*Це функція яка зрівнює два обєкта - чи однакові вони чи ні - корисно для дого щоб коли в фільтрі натискаєш очистити фільтр, запит не відправлявся*/

import { useEffect } from "react";

const examinationIsEqualObject = (object1, object2) => {
  const props1 = Object.getOwnPropertyNames(object1).sort();
  const props2 = Object.getOwnPropertyNames(object2).sort();

  let isEqual = true;

  if (props1.length !== props2.length) {
    return isEqual;
  }

  for (let i = 0; i < props1.length; i++) {
    if (object1[props1[i]] !== object2[props2[i]]) {
      isEqual = false;
      break;
    }
  }

  return isEqual;
};

const HookScrollTopWindowChangePage = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

export { examinationIsEqualObject, HookScrollTopWindowChangePage };