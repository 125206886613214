import React from "react";
import { StyledDecorationElement } from "../styles/styledDocumentElements";
import { NavLink } from "react-router-dom";
import { StyledSubDaysToEnd } from "./styledHeader";

const SubDaysToEnd = ({ subscriptionDaysToEnd, handleCancelShowSubEndDay }) => {
  const examinationColorShowSubscriptionEndDay = subscriptionDaysToEnd > 0 ? {
    bgBlock: "#ffeb3f",
    colorText: "#09080B"
  } : { bgBlock: "#f44336", colorText: "#FFFFFF" };

  return (
    <StyledSubDaysToEnd color={examinationColorShowSubscriptionEndDay.bgBlock}>
      <div className="header-sub-day-to-end">
        <StyledDecorationElement
          as={NavLink}
          to="/panel/balance"
          size="12px"
          color={examinationColorShowSubscriptionEndDay.colorText}
        >
          Your subscription will expire in {subscriptionDaysToEnd} days.
        </StyledDecorationElement>
        <StyledDecorationElement
          size="8px"
          color={examinationColorShowSubscriptionEndDay.colorText}
          cursor="pointer"
          onClick={handleCancelShowSubEndDay}
          className="icon-cancel"
        />
      </div>
    </StyledSubDaysToEnd>
  );
};

export default SubDaysToEnd;