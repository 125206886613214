import { useEffect, useState } from "react";

export const useDarkMode = () => {
  const [theme, setTheme] = useState("purple");
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = mode => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === "purple" ? setMode("blue") : setMode("purple");
  };
  const themeSelect = (theme) => {
    setMode(theme);
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    const colorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (localTheme === null && colorScheme) {
      setTheme("purple");
    } else {
      localTheme ? setTheme(localTheme) : setTheme("purple");
    }
    setMountedComponent(true);
  }, []);

  return [theme, themeToggler, themeSelect, mountedComponent];
};