const navbar = {
  ACCOUNT: "account:sidebar",
  GOODS: "goods:sidebar",
  PROJECT: "project:sidebar",
  CHAT: "chat:sidebar",
  CORPORATE_CHAT: "corporateChats:sidebar",
  LOGS: "logs:sidebar",
  SUBSCRIPTIONS: "subscriptions:sidebar",
  USERS: "users:sidebar",
  MANAGERS: "managers:sidebar",
  PAYMENT_SETTINGS: "payment_settings:sidebar",
  FEEDBACKS: "feedbacks:sidebar",
  ADMIN_TARIFFS: "admin_tariffs:sidebar",
  ADMIN_TARIFFS_AND_SUBSCRIPTIONS: "admin_tariffs_and_subscriptions:sidebar",
  OWNER_TARIFFS_AND_SUBSCRIPTIONS: "owner_tariffs_and_subscriptions:sidebar",
  BALANCE: "balance:sidebar",
  STATISTICS: "statistics:sidebar",
  ARCHIVE: "archive:sidebar",
  CARD_VERIFICATION: "card-verification:sidebar",
};

const goods = {
  ADMIN: "goods:admin",
  USER: "goods:user"
};

const account = {
  CLIENT: "account:client"
};

const chats = {
  MANAGER: "chats:manager",
  OWNER: "chats:owner",
  ADMIN: "chats:admin"
};

const corporateChats = {
  MANAGER: "corporateChats:manager",
  OWNER: "corporateChats:owner"
};

const statistics = {
  OWNER: "statistics:owner",
  MANAGER: "statistics:manager",
  ADMIN: "statistics:admin"
};

const assessments = {
  OWNER: "assessments:owner",
  MANAGER: "assessments:manager",
  ADMIN: "assessments:admin"
};

const project = {
  OWNER: "project:client",
  ADMIN: "project:admin"
};

const logs = {
  ADMIN: "logs:admin"
};

const subscriptions = {
  ADMIN: "subscriptions:admin",
  OWNER: "subscriptions:owner"
};

const tariffs = {
  ADMIN: "tariffs:admin",
  OWNER: "tariffs:owner"
};

const tariffsAndSubscriptions = {
  ADMIN: "tariffsAndSubscriptions:admin",
  OWNER: "tariffsAndSubscriptions:owner"
};

const balance = {
  OWNER: "balance:owner"
};

const users = {
  ADMIN: "users:admin"
};

const managers = {
  OWNER: "managers:owner",
  ADMIN: "managers:admin"
};

const adminUserShow = {
  OWNER: "adminUserShow:owner",
  MANAGER: "adminUserShow:manager"
};

const paymentSettings = {
  ADMIN: "paymentSettings:admin"
};

const feedbacks = {
  ACTIONS: "feedbacks:actions"
};

const cardVerification = {
  ADMIN: "card-verification:admin",
  OWNER: "card-verification:owner"
};

export {
  navbar,
  goods,
  account,
  project,
  chats,
  corporateChats,
  logs,
  subscriptions,
  tariffs,
  tariffsAndSubscriptions,
  balance,
  users,
  managers,
  paymentSettings,
  feedbacks,
  statistics,
  cardVerification,
  assessments,
  adminUserShow
};
