import React, { createContext, Suspense, useEffect, useMemo, useState } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/header/Header";
import { useCookies } from "react-cookie";
import axios from "axios";
import AutoLogoutModal from "./components/elements/modal/AutoLogoutModal";
import getUserInfo from "./utils/getUserInfo";
import { getExpirationDate, isExpired } from "./utils/checkExpiredToken";
import userAuthenticationConfig from "./utils/userAuthenticationConfig";
import { checkCookiesAndSet } from "./utils/cookies";
import eventBus from "./utils/eventBus";
import PageSpinner from "./components/elements/spinner/PageSpinner";
import ScrollToTopProvider from "./components/scrollTopTop/ScrollTopTop";
import Sound from "./components/elements/alert/playSound";
import { closableNotification } from "./components/notification/ClosableNotification";
import { getMercureTopics } from "./utils/getMercureTopics";
import { useDarkMode } from "./components/elements/customHooks/HookColorScheme";

import LoadRoute from "./components/loadRouter/LoadRoute";
import userRoutesConcat from "./routes/userRoutes";
import routes from "./routes/routes";

import { GlobalStyle } from "./components/styles/globalStyle";
import "./assets/fonts/default-icons/style.css";
import "./assets/css/rc-select.css";
import "rc-dialog/assets/index.css";
import "rc-notification/assets/index.css";
import "rc-dropdown/assets/index.css";
import "rc-checkbox/assets/index.css";
import "rc-switch/assets/index.css";
import "rc-pagination/assets/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "rc-tabs/assets/index.css";
import "rc-steps/assets/index.css";
import "rc-drawer/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "rc-slider/assets/index.css";
import "react-awesome-lightbox/build/style.css";
import "react-s-alert-v3/dist/s-alert-default.css";
import "react-s-alert-v3/dist/s-alert-css-effects/slide.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  browsers,
  exceptionPath,
  mercureTimeOut,
  mercureUrl,
  responseStatus,
  roles,
  settingsConsts,
  standardErrorsWay,
  userSettings
} from "./utils/consts";
import { ThemeProvider } from "styled-components";
import { purpleTheme } from "./components/styles/theme/purpleTheme";
import { blueTheme } from "./components/styles/theme/blueTheme";
import { parseAxiosError } from "./utils/response";
import useSettingChat from "./components/elements/customHooks/HookSettingChat";

export const AuthContext = createContext({});

function App() {
  const history = useHistory();

  const trustedCookies = ["projectToken"];
  const [cookies, setCookie] = useCookies(trustedCookies);
  const [isCreatedSubAndProject, setIsCreatedSubAndProject] = useState(null);
  const [sounds, setSounds] = useState([]);
  const [counter, setCounter] = useState(0);
  const [globalRerender, setGlobalRerender] = useState(false);
  const [subscriptionDaysToEnd, setSubscriptionDaysToEnd] = useState(null);

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("token") && !isExpired(getExpirationDate(localStorage.getItem("token"))));

  const isValidToken = () => {
    axios.post("/api/is-valid-token", {}, userAuthenticationConfig(true)).catch(() => {
      localStorage.removeItem("token");
      setAuthenticated(false);
      history.push("/login");
    });
  };

  const addManagerToProject = () => {
    axios.post("/api/add-managers-to-project", {}, userAuthenticationConfig()).
      then(response => {
        if (response.status === responseStatus.HTTP_CREATED) {
          closableNotification("You successfully added to project", "success");
        }
      }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    }).finally(() => {
      history.push("/");
    });
  };

  const offlineUser = async (expired) => {
    let user = getUserInfo();

    if (expired || !user) {
      return;
    }

    await axios.put("/api/users/" + user.userId, { isOnline: false, lastPing: null },
      userAuthenticationConfig()).then(response => {
    }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    });
  };

  const handleOnIdle = () => {
    eventBus.on("logout", (data) => {
      let user = getUserInfo();
      offlineUser(data?.expired).finally(() => {
        localStorage.removeItem("clientId");
        localStorage.removeItem("token");

        setAuthenticated(false);
        setSubscriptionDaysToEnd(null);
        history.push("/");
      });

      if (localStorage.getItem("first_login" + user.userId)) {
        localStorage.removeItem("first_login" + user.userId);
      }

    });

    window.addEventListener("storage", function (event) {
      if (event.key === "token" && !localStorage.getItem("token")) {
        eventBus.dispatch("logout");
      }
    });
  };

  const pong = () => {
    let user = getUserInfo();

    if (user) {
      axios.post("/api/pong", { user: user.userId }, userAuthenticationConfig());
    }
  };

  const getSubscriptionEndDate = () => {

    let user = getUserInfo();

    if (!user?.roles.includes(roles.OWNER)) {
      return;
    }

    axios.get("/api/get-subscription-end-date?ownerId=" + user.userId, userAuthenticationConfig()).
      then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setSubscriptionDaysToEnd(String(response.data));
        }
      }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      isValidToken();
    }

    let params = (new URL(document.location)).searchParams;

    checkCookiesAndSet(trustedCookies, setCookie, cookies);
    handleOnIdle();

    if (params.get("projectToken")) {
      if (!authenticated) {
        history.push("/login");
        return;
      }
      addManagerToProject();
    }
  }, []);

  let esDialogManager = null;
  let mercureDialogManagerTime = null;

  const mercureDialogManager = () => {
    clearTimeout(mercureDialogManagerTime);
    let user = getUserInfo();

    if (user?.roles.includes(roles.ADMIN)) {
      return;
    }

    mercureDialogManagerTime = setTimeout(() => {
      esDialogManager.close();
      esDialogManager = mercureDialogManager();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("dialog-manager", [{ id: user.userId }]),
      { path: "/" });

    esDialogManager = new EventSource(mercureUrl, { withCredentials: true });

    let tmp = [];

    esDialogManager.onmessage = (event) => {
      let dataMercure = JSON.parse(event.data);

      if (dataMercure.hasOwnProperty("operator") && dataMercure.hasOwnProperty(userSettings.USER_AUDIO)) {
        if (!dataMercure || !user) {
          return;
        }

        if (dataMercure[userSettings.USER_AUDIO] !== settingsConsts.TRUE_NUM || user?.userId !==
          dataMercure?.operator) {
          return;
        }

        if (dataMercure) {
          tmp.push(dataMercure);
        }

        setSounds(tmp);
        setCounter(tmp.length);
      }
    };

    esDialogManager.onerror = (event) => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esDialogManager = mercureDialogManager();
    };

    return esDialogManager;
  };

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    esDialogManager = mercureDialogManager();

    return () => {
      esDialogManager?.close();
      clearTimeout(mercureDialogManagerTime);
    };

  }, [authenticated]);

  let esPingPong = null;
  let mercurePingPongTime = null;

  const mercurePingPong = () => {
    clearTimeout(mercurePingPongTime);
    let user = getUserInfo();

    mercurePingPongTime = setTimeout(() => {
      esPingPong.close();
      esPingPong = mercurePingPong();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("ping-pong", [{ id: user?.userId }]),
      { path: "/" });

    esPingPong = new EventSource(mercureUrl, { withCredentials: true });

    esPingPong.onmessage = (event) => {
      pong();
    };

    esPingPong.onerror = (event) => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esPingPong = mercurePingPong();
    };

    return esPingPong;
  };

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    esPingPong = mercurePingPong();

    return () => {
      esPingPong.close();
      clearTimeout(mercurePingPongTime);
    };

  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    let user = getUserInfo();

    if (!user?.roles.includes(roles.OWNER)) {
      return;
    }

    axios.get("/api/users/is-created-sub-and-project/" + user.userId, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setIsCreatedSubAndProject(response.data);
      }
    }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    });
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    getSubscriptionEndDate();
  }, [authenticated]);

  const location = useLocation();

  const authRouteRender = () => {
    if (!authenticated) {
      return (
        routes.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    } else {
      return (
        userRoutesConcat.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    }
  };

  const [theme, themeToggler, themeSelect, mountedComponent] = useDarkMode();

  const themeMode = (theme) => {
    switch (theme) {
      case "purple":
        return purpleTheme;
      case "blue":
        return blueTheme;
    }
  };

  const themeModeMemo = useMemo(() => themeMode(theme), [theme]);

  const { handleChangeSettingsSendMess, settingChatSendMess } = useSettingChat();

  if (!mountedComponent) {
    return <div />;
  }

  return (
    <AuthContext.Provider
      value={{
        theme: { themeName: theme, themeMode, themeSelect, themeToggler },
        sendBtnSetting: { handleChangeSettingsSendMess, settingChatSendMess },
        authenticated,
        setAuthenticated,
        user: getUserInfo(),
        globalRerender,
        setGlobalRerender,
        isCreatedSubAndProject,
        setIsCreatedSubAndProject,
        setSubscriptionDaysToEnd
      }}
    >
      <ThemeProvider theme={themeModeMemo}>
        <HelmetProvider>
          <AutoLogoutModal
            authenticated={authenticated}
            setAuthenticated={setAuthenticated}
            setSubscriptionDaysToEnd={setSubscriptionDaysToEnd}
          />
          <Sound
            sounds={sounds}
            counter={counter}
          />
          {exceptionPath.every(element => element !== location.pathname) && <Header
            position={false}
            subscriptionDaysToEnd={subscriptionDaysToEnd}
            getSubscriptionEndDate={getSubscriptionEndDate}
          />}
          <ScrollToTopProvider>
            <Suspense fallback={<PageSpinner />}>
              <div className="pattern-block">
                <Switch>
                  {authRouteRender()}
                  <Redirect to="/page-not-found" />
                </Switch>
              </div>
            </Suspense>
          </ScrollToTopProvider>
          <GlobalStyle />
        </HelmetProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
