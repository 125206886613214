import styled from 'styled-components'
import {motion} from "framer-motion";
import { StyledHomePageSectionBlock } from "../styledHomePage";

export const StyledHeaderHomePageSection = styled.header``
export const StyledHeaderHomePageBlock = styled(StyledHomePageSectionBlock)`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 20;

  .header-left,
  .header-right {
    max-width: 285px;
    width: 100%;
    display: inline-flex;
    z-index: 10;
  }

  .header-right {
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    .header-left,
    .header-right {
      max-width: 90px;
    }
  }

  @media screen and (max-width: 576px) {
    .header-left {
      order: 2;
      justify-content: center;
    }

    .header-right {
      max-width: 50px;
      order: 3;
      z-index: 1;
    }
  }
`

export const StyledLogoHomeHeaderWrapper = styled(motion.div)`
  height: 90px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
 
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    transition: all .3s ease;
  }
  @media screen and (max-width: 768px) {
    height: 63px;
    width: 70px;
  }
`

export const StyledAuthButtonsHomeHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 15px;

  .login-button {
    position: relative;
    z-index: 10;
    outline: none;
    &:hover {
      box-shadow: none;
    }

    &:after {
      content: '';
      background: rgba(155, 104, 228, 0.2);
      filter: blur(5px);
      position: absolute;
      height: 20px;
      width: 100%;
      transition: all 0.2s;
      left: 0;
      opacity: 0;
    }

    &:hover:after {
      transition: all 0.2s;
      opacity: 1;
    }
    &__icon {
      display: none;
    }
  }

  .login-button,
  .registration-button {
    padding: ${({ home }) => home ? "6px 25px" : "2px 25px"};
  }

  .registration-button {
    border-radius: 10px;
    background: ${({ theme }) => theme.bodyColorText};
  }

  .login-button {
    color: #333333;
    border: none;

    &__icon {
      font-size: ${({ home }) => home ? "28px" : "20px"};
    }
  }
  
  @media screen and (max-width: 768px) {
    grid-gap: 0;
    .login-button {
      padding: 0;
      border: none;

      &__icon {
        height: 100%;
        width: 30px;
        padding: 0;
        margin: 0;
        color: #333333;
        display: flex;
        font-size: 25px;
        align-items: center;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;
      }

      &__text {
        display: none;
      }

      &:hover {
        border: none;
        background: none;
      }
    }

    .registration-button {
      display: none;
    }
  }
`