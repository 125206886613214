import styled from "styled-components";
import { StyledContainer } from "../styles/styledContainer";

export const StyledHeaderWrapper = styled.header`
  padding: 6px 0;
  width: 100%;
  min-height: 55px;
  align-items: center;
  display: flex;
  color: #fff;
  background: linear-gradient(195.8deg, #FFFFFF -2.63%, #F3F2F2 113.92%, #F3F2F2 113.94%);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;

  ${({ displayViewport }) => displayViewport ? `
      transition: all 0.2s linear;
      transform: translateY(0);
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  ` : `
      transform: translateY(-100%);
      opacity: 0;
      visibility: hidden;
      z-index: 1;
  `}
`;

export const StyledSubDaysToEnd = styled.div`
  width: 100%;
  position: absolute;
  top: 45px;
  display: flex;
  justify-content: center;
  background-color: ${({ color }) => color && color};

  .header-sub-day-to-end {
    max-width: 1280px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .icon-cancel {
      position: absolute;
      right: 20px;
    }
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;

  .header-left,
  .header-right {
    max-width: 285px;
    width: 100%;
    display: inline-flex;
  }

  .header-right {
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    .header-left,
    .header-right {
      max-width: 90px;
    }
  }

  @media screen and (max-width: 576px) {
    .header-left {
      order: 2;
      justify-content: center;
    }

    .header-right {
      max-width: 50px;
      order: 3;
    }
  }
`;