import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import Select, { Option } from "rc-select";
import { logs_levels } from "../../../utils/consts";

const LogsLevelSelect = ({ level, setLevel }) => {

  return (
    <StyledSelect>
      <StyledSelectLabel>Level</StyledSelectLabel>
      <Select
        className="custom-select"
        name="category"
        value={level ?? null}
        defaultValue={null}
        onChange={(value) => setLevel(value)}
        getPopupContainer={() => document.getElementById("filter")}
      >
        <Option value={null}>
          <div className="option-select-item">
            {"All"}
          </div>
        </Option>
        <Option value={logs_levels.INFO}>
          <div className="option-select-item">
            {logs_levels.INFO}
          </div>
        </Option>
        <Option value={logs_levels.ERROR}>
          <div className="option-select-item">
            {logs_levels.ERROR}
          </div>
        </Option>
        <Option value={logs_levels.WARNING}>
          <div className="option-select-item">
            {logs_levels.WARNING}
          </div>
        </Option>
      </Select>
    </StyledSelect>
  );
};

export default LogsLevelSelect;