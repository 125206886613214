import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledHomePageWrapper = styled.main`
  line-height: 25px;
  background-color: white;
  position: relative;
  
  .before-block {
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    &:before {
      width: 100%;
      max-width: 800px;
      height: 800px;
      content: '';
      position: absolute;
      top: -550px;
      left: -360px;
      border-radius: 50%;
      background: rgba(203,188,225,0.4);
      -webkit-filter: blur(125px);
      filter: blur(125px);
    }
    @media screen and (max-width: 576px) {
      &:before {
        left: -200px;
        -webkit-filter: blur(30px);
        filter: blur(30px);
      }
    }
  }
`;

export const StyledHiddenWrapper = styled.div`
  overflow: hidden;
`;

export const StyledHomePageContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const StyledHomePageSectionBlock = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const StyledMainTitleHomePage = styled(motion.div)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  color: ${({ color }) => color === "white" ? "#fff" : "#333333"};
  font-weight: 700;
  text-align: ${({ position }) => position === "center" ? "center" : "left"};
  line-height: ${({ lineHeight }) => lineHeight ? `${lineHeight}px` : "26px"};
`;

export const StyledDecorationElementHomePage = styled(motion.span)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : "100%"};
  display: ${({ display }) => display || "inline"};
  text-align: ${({ position }) => position ? position : "inherit"};
  line-height: ${({ lineHeight }) => lineHeight ? `${lineHeight}px` : "26px"};
  font-weight: ${({ weight }) => weight || "400"};
  font-size: ${({ size }) => size ? `${size}px` : "14px"};
  color: ${({ color }) => color || "#09080B"};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ underline }) => underline && `text-decoration: underline`};
  ${({ wrap }) => wrap && `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`;