import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { exceptionPath } from "../../utils/consts";

function ScrollToTopProvider ({ history, children }) {

  const refHistoryLocationPathName = useRef(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen(() => {

      refHistoryLocationPathName.current = history.location.pathname;

      if (refHistoryLocationPathName.current !== history.location.pathname ||
        exceptionPath.includes(refHistoryLocationPathName.current)) {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
}

export default withRouter(ScrollToTopProvider);