import styled from "styled-components";
import { StyledSelect } from "./styledSelect";

export const StyledFilterWrapper = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  position: relative;
`;

export const StyledFilterHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content) 1fr;
  grid-gap: 15px;
  
  @media screen and (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledFilterBody = styled.div`
  ${({ hide }) => hide === false && "height: 0"};
  ${({ hide }) => hide === false && "display: none"};
  ${({ hide }) => hide === true ? "animation: showFilter .15s ease" : "animation: none"};
  margin-top: 20px;
  padding: 15px;
  background-color: ${({theme}) => theme.neutralPrimary};

  @keyframes showFilter {
    0% {
      opacity: 0;
      transform: translateY(-25px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  @media screen and (max-width: 992px) {
    padding: 20px 15px;
  }
`;

export const StyledFilterTitle = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  margin-bottom: ${({mb}) => mb || 5}px;
  color: ${({theme}) => theme.main};
  font-size: 16px;
  font-weight: 700;
  opacity: 0.9;
`;

export const StyledFilterBlock = styled.div`
  display: grid;
  grid-template-columns: ${({ actions }) => actions ? "max-content" : "repeat(4, 1fr)"};
  grid-gap: 15px;
  &:not(&:last-child) {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #CFCFCF;
  }
  .input-group {
    margin-bottom: 0;
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const StyledPerPageSelect = styled(StyledSelect)`
  width: 70px;
  height: 42px;
  .custom-select {
    line-height: 20px;
    .rc-select-selector {
      min-height: 30px;
    }
    .rc-select-selection-item {
      padding: 10px 15px !important;
    }
    .option-select-item {
      width: 100%;
    }
    .rc-select-arrow {
      right: 8px;
      .rc-select-arrow-icon {
        &:before {
          font-size: 12px;
        }
      }
    }
  }
`;

export const StyledTotalItems = styled.div`
  p {
    color: ${({theme}) => theme.subMainColor};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    p {
      text-align: left;
    }
  }
`;