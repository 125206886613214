import {
  account,
  chats,
  goods,
  logs,
  managers,
  navbar,
  paymentSettings,
  project,
  subscriptions,
  tariffs,
  users,
  feedbacks,
  tariffsAndSubscriptions,
  balance,
  statistics,
  assessments,
  cardVerification,
  corporateChats,
  adminUserShow
} from "./rbac-consts";

const rules = {
  ROLE_OWNER: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.ACCOUNT,
      navbar.PROJECT,
      navbar.CHAT,
      navbar.CORPORATE_CHAT,
      navbar.OWNER_TARIFFS_AND_SUBSCRIPTIONS,
      navbar.BALANCE,
      navbar.STATISTICS,
      navbar.CARD_VERIFICATION,
      goods.USER,
      account.CLIENT,
      adminUserShow.OWNER,
      project.OWNER,
      chats.OWNER,
      corporateChats.OWNER,
      navbar.MANAGERS,
      managers.OWNER,
      navbar.SUBSCRIPTIONS,
      subscriptions.OWNER,
      tariffsAndSubscriptions.OWNER,
      balance.OWNER,
      subscriptions.OWNER,
      statistics.OWNER,
      cardVerification.OWNER,
      assessments.OWNER
    ],
    dynamic: {}
  },
  ROLE_MANAGER: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.ACCOUNT,
      navbar.CHAT,
      navbar.CORPORATE_CHAT,
      navbar.STATISTICS,
      goods.USER,
      account.CLIENT,
      chats.MANAGER,
      corporateChats.MANAGER,
      statistics.MANAGER,
      assessments.MANAGER,
      adminUserShow.MANAGER
    ],
    dynamic: {}
  },
  ROLE_ADMIN: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.PROJECT,
      navbar.LOGS,
      navbar.SUBSCRIPTIONS,
      navbar.ADMIN_TARIFFS,
      navbar.ADMIN_TARIFFS_AND_SUBSCRIPTIONS,
      navbar.PAYMENT_SETTINGS,
      navbar.USERS,
      navbar.FEEDBACKS,
      navbar.STATISTICS,
      navbar.CARD_VERIFICATION,
      project.ADMIN,
      logs.ADMIN,
      users.ADMIN,
      chats.ADMIN,
      subscriptions.ADMIN,
      tariffs.ADMIN,
      paymentSettings.ADMIN,
      feedbacks.ACTIONS,
      statistics.ADMIN,
      cardVerification.ADMIN,
      assessments.ADMIN
    ],
    dynamic: {}
  }
};

export default rules;
