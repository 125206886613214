import React, { useContext, useEffect, useState } from "react";
import Dialog from "rc-dialog";
import { useIdleTimer } from "react-idle-timer";

import { StyledButton } from "../../styles/styledButton";
import eventBus from "../../../utils/eventBus";
import { useHistory } from "react-router-dom";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { AuthContext } from "../../../App";

const AutoLogoutModal = ({ authenticated, setAuthenticated, setSubscriptionDaysToEnd }) => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [visible, setVisible] = useState(false);

  const handleOnIdle = () => {
    if (authenticated) {
      eventBus.dispatch("autoLogoutEvent");
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"
    ],
    crossTab: true,
    timeout: 1000 * 60 * 60,
    syncTimers: 200,
    onIdle: handleOnIdle
  });

  useEffect(() => {
    eventBus.on("autoLogoutEvent", () => {
      if (authenticated) {
        axios.put("/api/users/" + user.userId, { isOnline: false, lastPing: null },
          userAuthenticationConfig()).catch(error => {
        }).finally(() => {
          setVisible(true);

          localStorage.removeItem("clientId");
          localStorage.removeItem("token");

          setAuthenticated(false);
          setSubscriptionDaysToEnd(null);
          history.push("/");
        });
      }

      if (localStorage.getItem("first_login" + user.userId)) {
        localStorage.removeItem("first_login" + user.userId);
      }

    });
    eventBus.remove("autoLogoutEvent");
  }, [authenticated]);

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title="Attention"
      forceRender={false}
      className="default-modal"
    >
      <div className="default-modal__content">
        <p>
          For the safety and security of your account, an automatic logout from your account was performed.
        </p>
        <p>
          To continue working, please log in again.
        </p>
      </div>
      <div className="default-modal__footer">
        <StyledButton
          color="main"
          border
          onClick={() => setVisible(false)}
        >
          Ок
        </StyledButton>
      </div>
    </Dialog>
  );
};

export default AutoLogoutModal;