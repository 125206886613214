import styled from "styled-components";

export const StyledCopyToClipboardWrapper = styled.div`
  ${({ noWrap }) => noWrap && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  .icon-copy-alt {
    padding-left: 5px;
    color: #c8b19b;
    font-size: 14px;
    display: inline-flex;
    transition: all .3s ease;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      color: #3ACBEB;
      opacity: 1;
    }
  }
`;