import { createGlobalStyle } from "styled-components";
import pattern from '../../assets/images/pattern/pattern.svg'


const mainFont = "Inter, sans-serif";
const mainFontSize = "14px";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  .pattern-block {
    background-image: url(${pattern});
    background-size: 2400px;
    background-repeat: repeat-y;
    background-position: center -550px;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.bodyColorText};
    font-family: ${mainFont};
    font-size: ${mainFontSize};
    letter-spacing: 0.2px;
    line-height: 22px;
    background-color: ${({ theme }) => theme.body};
    scroll-padding-top: 80px;
  }

  .description-text {
    text-indent: 1.5em;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, select, input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  button {
    padding: 0;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
  }

  .default-link {
    color: ${({ theme }) => theme.main};

    &:hover {
      text-decoration: underline;
    }
  }

  .center-btn-block {
    margin: 0 auto;
  }

  .display-none {
    display: none;
  }

  //Progressbar custom style
  #nprogress .bar {
    height: 2px;
    background: #ffffff;
    z-index: 1999;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff;
  }

  #nprogress .spinner-icon {
    display: none;
    border-top-color: #ffffff;
    border-left-color: #ffffff;
  }

  //AntDesign dialog window
  .default-modal {
    margin-top: 130px;

    .rc-dialog-close {
      outline: none;
    }

    .rc-dialog-header, .rc-dialog-content {
      min-width: 300px;
      background-color: #fff;
    }

    .rc-dialog-header {
      padding: 15px;
      color: #656565;
      border-bottom: 1px solid #F0F0F0;
    }

    .rc-dialog-title {
      font-size: 16px;
      color: ${({ theme }) => theme.main};
    }

    .rc-dialog-footer {
      padding: 0;
    }

    &__body-content{
      word-break: break-word;
      p:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &__body-footer {
      padding-top: 15px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(75px, max-content));
      grid-gap: 15px;
      justify-content: end;
    }

    @media screen and (max-height: 992px) {

      max-height: calc(100% - 30px);

    }
  }

  .cookie-preference-modal {
    position: fixed;
    top: -80px;
    
    @media screen and (max-width: 768px) {
      padding-top: 0;
      top: -100px;
    }
  }

  .default-modal_h-center {
    display: flex;
    align-items: center;
  }

  .default-modal_w-center {
    .default-modal__content {
      margin: 0 auto;
      word-break: break-all;
    }

    .modal-control {
      display: flex;
      grid-gap: 10px;
    }
  }

  //rc-steps
  .rc-steps {
    justify-content: center;
  }

  //AntDesign notifications
  .rc-notification {
    padding: 0;
    transform: translateX(-50%);
    z-index: 1055;
    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: inherit;
      left: 0 !important;
    }

    .rc-notification-notice-close {
      color: black;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .rc-notification-notice {
      margin: 0;
      padding: 5px 20px 0 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }

    .message-with-icon {
      min-height: 50px;
      padding: 15px 15px 15px 55px;
      color: #202020;
      display: inline-flex;
      border-radius: 5px;
      position: relative;

      &:before {
        width: 25px;
        height: 25px;
        font-size: 22px;
        font-family: 'default-icons', serif;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        left: 15px;
      }
    }

    .message-with-icon.success {
      background-color: #e3f7e4;

      &:before {
        content: '\\e922';
        color: #4fba6e;
      }
    }

    .message-with-icon.error {
      background-color: #FFECEE;

      &:before {
        content: '\\e908';
        color: #FE6A74;
      }
    }

    .message-with-icon.info {
      background-color: #e6f7ff;

      &:before {
        content: '\\e942';
        color: #3286d9;
      }
    }

    .message-with-icon.warning {
      background-color: #fdf3c9;

      &:before {
        content: '\\e91e';
        color: #efc100;
      }
    }
  }

  //AntDesign custom tabs
  .rc-tabs-dropdown {
    color: #c8b19b;
    background-color: rgba(160, 150, 130, 0.15);
    border: 1px solid #c8b19b;
  }

  .default-tabs {
    border: none;
    grid-gap: 20px;

    .rc-tabs-nav-more {
      display: none;
    }

    .rc-tabs-nav-list {
      .rc-tabs-tab {
        font-size: 16px;
        font-weight: 700;
        align-items: end;

        &:hover {
          color: ${({ theme }) => theme.rcTabs["rc-tabs-tab-active"].color};
          opacity: 1;
        }

        .rc-tabs-tab-btn {
          outline: none;
          line-height: 20px;
          font-weight: 400;
        }
      }

      .rc-tabs-tab-active {
        opacity: 1;
      }

      .rc-tabs-ink-bar {
        height: 2px;
        background: ${({ theme }) => theme.rcTabs["rc-tabs-ink-bar"].background};
      }
    }

    .rc-tabs-content-holder {
      .rc-tabs-tabpane {
        outline: none;
      }
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, auto);
    }
  }

  .default-tabs-left {
    display: grid;
    grid-template-columns: 256px auto;

    .rc-tabs-tab {
      margin: 0;
      padding: 5px 15px;
    }

    .rc-tabs-ink-bar {
      display: none;
    }

    .rc-tabs-tab-active {
      color: #c8b19b;
      font-weight: 700;
      background-color: rgba(160, 150, 130, 0.15);
      border: 1px solid #c8b19b;
      border-radius: 5px;
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }

  .default-tabs-top {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 55px auto;
    overflow: inherit;

    .rc-tabs-nav-wrap {
      border-bottom: 1px solid #CFCFCF;
    }

    .rc-tabs-nav-wrap-ping-left::before, .rc-tabs-nav-wrap-ping-right::after {
      border: none;
    }

    .rc-tabs-tab {
      margin-right: 20px;
      padding: 10px 0;
      background: transparent;
      opacity: 0.4;

      &:last-child {
        margin: 0;
      }
    }

    .rc-tabs-tab-active {
      color: ${({ theme }) => theme.rcTabs["rc-tabs-tab-active"].color};

      .rc-tabs-tab-btn {
        font-weight: 600 !important;
      }
    }

    .rc-tabs-nav-more {
      min-width: 25px;
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      grid-template-rows: minmax(40px, auto) auto;
    }
  }

  //AntDesign custom submenu 
  .card-submenu {
    background-color: #595959;
    border-color: #595959;
    box-shadow: none;

    .rc-dropdown-menu-item {
      padding: 0;

      a, button {
        width: 100%;
        padding: 7px 10px;
        color: #fff;
        font-weight: 700;
        display: inline-grid;
        grid-template-columns: 14px max-content;
        grid-gap: 10px;
        text-align: left;
        border: none;
        outline: none;

        span {
          font-size: 14px;
          opacity: 0.5;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    .rc-dropdown-menu-item-selected {
      background-color: transparent;

      &:after {
        display: none;
      }
    }
  }

  .dropdown-wrapper {
    width: 100px;
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 8px rgb(0 0 0 / 20%);

    ul {
      li {
        padding: 10px;

        &:first-child {
          border-radius: 5px 5px 0 0;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        &:hover {
          background: #f9f9f9
        }
      }
    }
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item:hover,
  .rc-dropdown-menu > .rc-dropdown-menu-item-active,
  .rc-dropdown-menu > .rc-dropdown-menu-item-selected {
    background-color: #595959 !important;

    &:hover {
      background-color: black !important;
    }
  }

  .rc-dropdown {
    z-index: 1050;
  }

  //rc-switch
  .rc-switch {
    opacity: 0.5;
    background: #FFFFFF;
    border: 1px solid #9B68E4;

    &:after {
      background: #9B68E4;
    }
  }

  .rc-switch-checked {
    border: 1px solid ${({ theme }) => theme.main};
    background-color: ${({ theme }) => theme.main};
    opacity: 1 !important;

    &:after {
      background-color: #FFFFFF;
    }
  }

  //AntDesign select
  .rc-select-item-empty {
    padding: 12px 22px;
  }

  .rc-select-selector {
    min-height: 40px;

    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        display: inline-flex;
      }
    }

    span.rc-select-selection-item, span.rc-select-selection-placeholder {
      padding: 6px 15px !important;
      display: flex;
    }
  }

  .rc-select-dropdown {
    box-shadow: 0 1px 8px rgb(0 0 0 / 20%);
    z-index: 1051;

    .rc-select-item-option {
      padding: 8px 22px;
      background: #FFFFFF;
      cursor: pointer;

      &:hover {
        color: #FFFFFF;
        background-color: ${({ theme }) => theme.select.hover.bgSelect};
      }

      .option-select-item {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;

        img {
          height: 22px;
          width: 22px;
          margin-right: 20px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .rc-select-item-option-selected {
      color: #fff;
      background: ${({ theme }) => theme.select.bgSelected};

      .rc-select-item-option-state {
        display: none;
      }
    }
  }

  //AntDesign custom checkbox
  .default-checkbox {
    input {
      width: 17px;
      height: 17px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rc-checkbox-inner {
      width: 17px;
      height: 17px;
      border-color: transparent;
      background-color: #fff;
      box-shadow: 0 1px 8px rgb(0 0 0 / 30%);

      &:after {
        border: none
      }
    }
  }

  .rc-checkbox-checked {
    .rc-checkbox-inner {
      background-color: #c8b19b;

      &:after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  //AntDesign custom pagination 

  .rc-pagination-simple {
    .rc-pagination-prev, .rc-pagination-next {
      .rc-pagination-item-link {
        padding: 10px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #AEAEAE;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme["rc-pagination"].border};
        border-radius: 20px;
        outline: none;

        a {
          color: #AEAEAE;
        }
      }
    }

    .rc-pagination-simple-pager {
      input {
        border-radius: 20px;
        border: 1px solid ${({ theme }) => theme["rc-pagination"].border};

        &:hover {
          border: 1px solid ${({ theme }) => theme["rc-pagination"].border};
          background-color: ${({ theme }) => theme["rc-pagination"].backgroundColorActive} a {
            color: #c8b19b;
          }
        }
      }
    }
  }


  .default-pagination {
    padding: 30px 0;
    display: flex;
    justify-content: center;

    .rc-pagination-item, .rc-pagination-item-link {
      padding: 10px 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #AEAEAE;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme["rc-pagination"].border};
      border-radius: 20px;
      outline: none;

      a {
        color: #AEAEAE;
      }

      &:hover {
        color: #AEAEAE;
        border: 1px solid ${({ theme }) => theme["rc-pagination"].border};
        background-color: ${({ theme }) => theme["rc-pagination"].backgroundColorActive} a {
          color: #c8b19b;
        }
      }
    }

    .rc-pagination-jump-next, .rc-pagination-jump-prev {
      button {
        padding: 0 14px;
      }
    }

    .rc-pagination-item-active {
      color: #fff;
      background-color: ${({ theme }) => theme["rc-pagination"].backgroundColorActive};
      border: 1px solid ${({ theme }) => theme["rc-pagination"].border};
      border-radius: 20px;

      a {
        color: #fff;
      }

      &:hover a {
        color: #fff;
      }
    }
  }

  //AntDesign drawer custom style
  .drawer.drawer-open .drawer-mask {
    opacity: 0.45;
  }

  .drawer-left .drawer-content {
    background-color: #FFFFFF;
  }

  .drawer-content-wrapper {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: saturate(180%) blur(20px);

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(50deg, rgba(255, 255, 255, 0) 0%, rgb(255 255 255) 55%, rgb(255 255 255) 100%);
    }
  }

  .grecaptcha-badge {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  @keyframes loadContent {
    0% {
      opacity: 0;
      transform: translateY(-25px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  //rc-slider

  .rc-slider {
    &-handle {
      width: 22px;
      height: 22px;
      background: #D9D9D9;
      box-shadow: 1.81012px 1.81012px 4.52531px rgba(0, 0, 0, 0.25) !important;
      border: 5px solid #FFFFFF;
      top: 2px;
      opacity: 1;

      &-dragging {
        border-color: #FFFFFF !important;
        box-shadow: none !important;
      }
    }


    &-step {
      display: none;
    }

    &-rail {
      height: 7px;
      background: linear-gradient(270deg, #3A3A3B 1.97%, #BD3D3D 17.04%, #EE7423 34.08%, #E1CD19 50.51%, #3DBE57 66.95%, #2394EE 83.99%, #8745AF 98.17%), linear-gradient(180deg, #B33737 0%, #B33737 56.25%);
    }

    &-track {
      background-color: inherit;
    }

    &-mark {
      display: none;
    }
  }


  //swiper 

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  //Alert-notification
  .s-alert-box {
    background: #9B68E4;
    border-radius: 7px;
  }
`;