import styled from "styled-components";

const size = {
  size: ""
};

const changeContainerSize = (size) => {
  switch (size) {
    case "xl":
      return `
        max-width: 1920px
      `;
    default:
      return `
        max-width: 1280px
      `;
  }
};

export const StyledContainer = styled("div", size)`
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  ${({ padding }) => padding ? `padding: ${padding}` : "padding: 0 15px"};
  position: relative;
  background-color: #fff;
  ${({ wrapper }) => wrapper !== "content" && `min-height: calc(100vh - 55px)`};
  ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 15px`};
  @media screen and (max-width: 992px) {
    ${({ wrapper }) => wrapper !== "content" && `height: auto`};
  }
`;

export const StyledContentWrapper = styled.div`
  min-height: ${({ height }) => height === "auto" ? "auto" : `min-height: calc(100vh - 55px)`};
  margin-top: 20px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0 3px 12px rgba(113, 122, 134, 0.3);
  @media screen and (max-width: 599px) {
    margin-top: 15px;
    padding: 15px;
  }
`;

export const StyledMovingWrapper = styled.div`
  ${({ hide }) => hide === false && "height: 0"};
  ${({ hide }) => hide === false && "display: none"};
  ${({ hide }) => hide === true ? "animation: loadContent .15s ease" : "animation: none"};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb ? `margin-bottom: ${mb}px` : "margin-bottom: 15px"};
  padding: ${({ padding }) => padding ? `${padding}px` : "20px"};
  border-radius: 10px;
  @media screen and (max-width: 992px) {
    padding: ${({ padding }) => padding ? `${padding}px` : "20px 15px"};
  }
`;