import styled from "styled-components";

export const StyledSeparator = styled.div`
  position: relative;
  display: block;
  padding: 10px 0;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 262px;
    height: 1px;
    background-color: #F0F0F0;
  }
`;

export const StyledAuthButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 15px;

  .login-button {
    outline: none;

    &__icon {
      display: none;
    }
  }
  
  .login-button,
  .registration-button {
    padding: 4px 16px;
  }

  @media screen and (max-width: 768px) {
    grid-gap: 0;
    .login-button {
      padding: 0;
      border: none;
      &__icon {
        height: 100%;
        width: 30px;
        padding: 0;
        margin: 0;
        color: #FFFFFF;
        display: flex;
        font-size: 22px;
        align-items: center;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          color: ${({ theme }) => theme.sidebar.iconMobile.hover};
        }
      }

      &__text {
        display: none;
      }

      &:hover {
        border: none;
        background: none;
      }
    }

    .registration-button {
      display: none;
    }
  }
`;

export const StyledSidebarWrapper = styled.div``;

export const StyledSidebarAction = styled.div`
  display: grid;
  grid-template-columns: repeat(2,max-content);
  grid-gap: 20px;
  
  button {
    width: 30px;
    padding: 0;
    margin: 0;
    display: contents;
    color: #333333;
    font-size: 25px;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s ease;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSidebar = styled.div`
  .icon-cancel {
    margin-bottom: 15px;
    display: none;
    font-size: 18px;
    color: #A9A9A9;
    &:hover {
      color: ${({ theme }) => theme.main};
      transition: all .3s ease;
      transform: rotate(360deg);
      cursor: pointer;
    }
  }
  
  @media screen and (max-width: 576px) {
    .icon-cancel {
      display: block;
    }
  }
`;

export const StyledSidebarItem = styled.div`
  cursor: pointer;
  position: relative;
  ${({disable}) => disable && `
    pointer-events: none;
    background: #a9a9a929;
  `}
  
  .sidebar-link {
    color: #A9A9A9;

    &__icon {
      width: 20px;
      height: 20px;
      display: contents;
      font-size: 18px;
      text-align: center;
    }

    &__title {
      padding-left: 15px;
    }

    &_current {
      background-color: ${({ theme }) => theme.sidebar.sidebarBtn.current.backgroundCurrent};
      color: ${({ theme }) => theme.sidebar.sidebarBtn.color};
    }

    &:hover {
      transition: all .3s ease;
      background-color: #FAF7FF;

      .sidebar-link__title, .sidebar-link__icon {
        color: ${({ theme }) => theme.sidebar.sidebarBtn.color};
      }
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  padding: 14px 25px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
`;

export const StyledAccount = styled.div`
  margin-bottom: 10px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F0F0F0;
  color: #A9A9A9;
  .user {
    width: 100%;

    &__name {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.sidebar.sidebarInfoUser.color};
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      img {
        margin-left: 6px;
      }
    }

    &__email {
      color: ${({ theme }) => theme.sidebar.sidebarInfoUser.color};
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      opacity: 0.5;
    }
    
    &__balance {
      margin-top: 5px;
      a {
        display: inline-grid;
        grid-template-columns: repeat(3, max-content);
        align-items: center;
        span {
          max-width: 170px;
          width: 100%;
          padding: 0 3px 0 5px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover {
          color: ${({theme}) => theme.main};
        }
      }
    }
  }

  .sidebar-account__settings {
    padding: 2px 0;
    .settings-link {
      height: 18px;
      width: 18px;
      color: #A9A9A9;
      font-size: 18px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: ${({ theme }) => theme.main};
        transition: all .3s ease;
        transform: rotate(360deg);
      }
    }
  }
`;

export const StyledBadgeNotification = styled.div`
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #09080B;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  background: #FFD76D;
  border-radius: 10px;
  z-index: auto;
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-10px);
`;