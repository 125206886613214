import { roles } from "../../../utils/consts";
import adminJewelry from "../../../assets/images/role-jewelry/admin-jewelry.svg";
import ownerJewelry from "../../../assets/images/role-jewelry/owner-jewelry.svg";
import managerJewelry from "../../../assets/images/role-jewelry/manager-jewelry.svg";

const jewelryRole = (role) => {
  if (role) {
    switch (role.toString()) {
      case roles.ADMIN:
        return adminJewelry;
      case roles.OWNER:
        return ownerJewelry;
      case roles.MANAGER:
        return managerJewelry;
    }
  }
};

const nameRole = (role) => {
  if (role) {
    switch (role.toString()) {
      case roles.ADMIN:
        return "Admin";
      case roles.OWNER:
        return "Owner";
      case roles.MANAGER:
        return "Manager";
    }
  }
};

export {
  jewelryRole,
  nameRole
};