import React, { useState } from "react";
import DelayInput from "../../inputGroup/DelayInputGroup";
import LogsRoleSelect from "../../logs/LogsRoleSelect";
import LogsLevelSelect from "../../logs/LogsLevelSelect";
import DatePicker from "react-date-picker";
import { DateToTimestampEnd, DateToTimestampStart, TimestampToDate } from "../../../../utils/timestampToDate";

import { StyledButton } from "../../../styles/styledButton";
import { StyledDatepicker, StyledDatepickerLabel } from "../../../styles/styledDatepicker";
import {
  StyledFilterBlock,
  StyledFilterBody,
  StyledFilterHead,
  StyledFilterTitle,
  StyledFilterWrapper
} from "../../../styles/styledFilter__NEW";
import FilterItemsPerPage from "../../filterSelect/FilterItemsPerPage";
import FilterTotalItems from "../../filterSelect/FilterTotalItems";

const LogsFilter = ({ filterData, setFilterData, paginationInfo, setPaginationInfo }) => {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = () => {
    setVisible(!visible);
  };

  const handleClearFilter = () => {
    setFilterData({
      "page": 1,
      "user": "",
      "role": null,
      "context": "",
      "level_name": null,
      "datetime[gte]": "",
      "datetime[lte]": "",
      "message": "",
      "ip": ""
    });
  };

  return (
    <StyledFilterWrapper
      id="filter"
      pb="15"
    >
      <StyledFilterHead>
        <StyledButton
          color="main"
          onClick={onVisibleChange}
        >
          Filter
        </StyledButton>
        <FilterItemsPerPage
          paginationInfo={paginationInfo}
          setPaginationInfo={setPaginationInfo}
          filterData={filterData}
          setFilterData={setFilterData}
        />
        <FilterTotalItems
          paginationInfo={paginationInfo}
        />
      </StyledFilterHead>
      <StyledFilterBody hide={visible}>
        <StyledFilterTitle>
          Filter by:
        </StyledFilterTitle>
        <StyledFilterBlock>
          <DelayInput
            label="User"
            type="text"
            id="user"
            name="user"
            onChange={event => setFilterData({ ...filterData, "user": event.target.value, page: 1 })}
            value={filterData["user"]}
            autoComplete="off"
            debounceTimeout={600}
          />
          <DelayInput
            label="Action"
            type="text"
            id="message"
            name="message"
            onChange={event => setFilterData({ ...filterData, "message": event.target.value, page: 1 })}
            value={filterData["message"]}
            autoComplete="off"
            debounceTimeout={600}
          />
          <LogsRoleSelect
            role={filterData["role"]}
            setRole={(data) => setFilterData({
              ...filterData,
              "role": data,
              page: 1
            })}
          />
          <LogsLevelSelect
            level={filterData["level_name"]}
            setLevel={(data) => setFilterData({
              ...filterData,
              "level_name": data,
              page: 1
            })}
          />
          <DelayInput
            label="Ip"
            type="text"
            id="ip"
            name="ip"
            onChange={event => setFilterData({ ...filterData, "ip": event.target.value, page: 1 })}
            value={filterData["ip"]}
            autoComplete="off"
            debounceTimeout={600}
          />
        </StyledFilterBlock>
        <StyledFilterTitle>
          By date:
        </StyledFilterTitle>
        <StyledFilterBlock>
          <StyledDatepicker>
            <StyledDatepickerLabel htmlFor="dateFrom">
              Date from
            </StyledDatepickerLabel>
            <DatePicker
              format="dd-MM-y"
              locale="en"
              id="dateFrom"
              name="dateFrom"
              className="date-input"
              onChange={dateFrom => setFilterData({
                ...filterData,
                "datetime[gte]": DateToTimestampStart(dateFrom),
                page: 1
              })}
              value={TimestampToDate(filterData["datetime[gte]"])}
            />
          </StyledDatepicker>
          <StyledDatepicker>
            <StyledDatepickerLabel htmlFor="dateTo">
              Date to
            </StyledDatepickerLabel>
            <DatePicker
              format="dd-MM-y"
              locale="en"
              id="dateTo"
              name="dateTo"
              className="date-input"
              onChange={dateTo => setFilterData({
                ...filterData,
                "datetime[lte]": DateToTimestampEnd(dateTo),
                page: 1
              })}
              value={TimestampToDate(filterData["datetime[lte]"])}
            />
          </StyledDatepicker>
        </StyledFilterBlock>
        <StyledFilterBlock actions>
          <StyledButton
            weight="600"
            border
            color="danger"
            onClick={handleClearFilter}
          >
            Clear filter
          </StyledButton>
        </StyledFilterBlock>
      </StyledFilterBody>
    </StyledFilterWrapper>
  );
};

export default LogsFilter;