import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkFilterItem, fetchFilterData } from "../../../utils/fetchFilterData";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus, standardErrorsWay } from "../../../utils/consts";
import Pagination from "rc-pagination";
import LogsList from "./LogsList";
import LogsFilter from "../../elements/filter/logs/LogsFilter";
import { parseAxiosError } from "../../../utils/response";
import queryString from "query-string";
import { HookScrollTopWindowChangePage } from "../../../utils/utilsFunc";

const LogsContainer = () => {
  const [logs, setLogs] = useState(null);

  const history = useHistory();
  let searchParams = queryString.parse(history.location.search);

  const didMount = useRef(false);

  const [paginationInfo, setPaginationInfo] = useState({
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: checkFilterItem(history, "itemsPerPage", 50, true)
  });

  const [filterData, setFilterData] = useState({
    "page": checkFilterItem(history, "page", 1, true),
    "user": checkFilterItem(history, "user", null),
    "role": checkFilterItem(history, "role", null),
    "message": checkFilterItem(history, "message", null),
    "level_name": checkFilterItem(history, "level_name", null),
    "datetime[gte]": checkFilterItem(history, "datetime[gte]", null, true),
    "datetime[lte]": checkFilterItem(history, "datetime[lte]", null, true),
    "ip": checkFilterItem(history, "ip", null)
  });

  useEffect(() => {
    fetchLogs();
  }, [filterData, paginationInfo.itemsPerPage]);

  const fetchLogs = () => {
    let filterUrl = fetchFilterData({ ...filterData, itemsPerPage: paginationInfo.itemsPerPage });
    history.replace(filterUrl);

    axios.get(`/api/app-logs${filterUrl}&itemsPerPage=${paginationInfo.itemsPerPage}`,
      userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setLogs(response.data["hydra:member"]);

        setPaginationInfo({
          ...paginationInfo,
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"]
        });
      }
    }).catch(error => {
      parseAxiosError(eval(standardErrorsWay));
    });
  };

  const onChangePage = (page) => {
    let filterUrl = fetchFilterData({ ...searchParams, page });
    history.push(filterUrl);
  };

  useEffect(() => {
    if (didMount.current && searchParams.page && (Number(searchParams.page) !== filterData.page)) {
      setFilterData(prev => ({ ...prev, page: Number(searchParams.page) }));
    }

    didMount.current = true;
  }, [searchParams.page]);

  useEffect(() => {
    if (didMount.current && searchParams.itemsPerPage && (Number(searchParams.itemsPerPage) !== paginationInfo.itemsPerPage)) {
      setPaginationInfo(prev => ({ ...prev, itemsPerPage: Number(searchParams.itemsPerPage) }));
    }

    const handlePopState = () => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });

      }, 100);
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [searchParams.itemsPerPage]);

  return (
    <>
      <LogsFilter
        filterData={filterData}
        setFilterData={setFilterData}
        paginationInfo={paginationInfo}
        setPaginationInfo={setPaginationInfo}
      />
      <LogsList
        logs={logs}
      />
      {paginationInfo.totalPageCount &&
        <Pagination
          className="default-pagination"
          total={paginationInfo.totalItems}
          current={filterData.page}
          onChange={(page) => {
            onChangePage(page);
            HookScrollTopWindowChangePage();
          }}
          pageSize={paginationInfo.itemsPerPage}
        />}
    </>
  );
};

export default LogsContainer;