import React from "react";
import { NavLink } from "react-router-dom";
import mainLogo from "../../../assets/images/main-logo.svg";

import { StyledLogoWrapper } from "./styledLogo";

const Logo = () => {

  // - не дропнув код оскільки я впевнений, що він тут ще потрібен буде

  // const { theme } = useContext(AuthContext);
  // const {themeName} = theme
  //
  // const themeLogo = (theme) => {
  //   switch (theme) {
  //     case "purple":
  //       return logoPurple
  //     case "blue":
  //       return logoBlue
  //   }
  // }
  //
  // const themeLogoMemo = useMemo(() => themeLogo(themeName),[themeName])

  return (
    <NavLink to="/">
      <StyledLogoWrapper
        whileHover={{ rotate: -20 }}
      >
        <img
          src={mainLogo}
          alt="chat-logo"
        />
      </StyledLogoWrapper>
    </NavLink>
  );
};

export default Logo;