import React from "react";
import Notification from "rc-notification";

let notification = null;
Notification.newInstance({ maxCount: 1 }, (n) => notification = n);

export const closableNotification = (message, type) => {

  if (!message && !localStorage.getItem("token")) {
    return;
  }

  const key = Date.now();

  const close = (key) => {
    notification.removeNotice(key);
  };

  const closeAfterClick = () => {
    document.addEventListener("click", function (e) {
      if (e.target.id !== "closableNotification") {
        close(key);
      }
    });
  };

  setTimeout(closeAfterClick, 1000);

  notification.notice({
    content: <span
      id="closableNotification"
      className={`message-with-icon ${type}`}
    >{message}</span>,
    duration: null,
    closable: true,
    key
  });
};