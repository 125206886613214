import React, { useEffect, useState } from "react";
import Select, { Option } from "rc-select";

import { StyledPerPageSelect } from "../../styles/styledFilter__NEW";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { fetchFilterData } from "../../../utils/fetchFilterData";

const FilterItemsPerPage = ({ setPaginationInfo, custom, paginationInfo, filterData, setFilterData }) => {

  const [value, setValue] = useState(paginationInfo.itemsPerPage ?? 10);
  const items = custom ? custom : [10, 20, 30, 50, 100];

  const history = useHistory();
  let searchParams = queryString.parse(history.location.search);

  const handleChangeValue = (value) => {
    if (filterData && setFilterData) {
      setFilterData((prevState) => ({ ...prevState, ["page"]: 1 }));
    }
    setPaginationInfo({ ...paginationInfo, itemsPerPage: value });

    let filterUrl = fetchFilterData({ ...searchParams, itemsPerPage: value, page: 1 });
    history.push(filterUrl);

    return value;
  };

  useEffect(() => {
    setValue(paginationInfo.itemsPerPage);
  }, [paginationInfo.itemsPerPage]);

  return (
    <StyledPerPageSelect width="130">
      <Select
        className="custom-select"
        name={"select"}
        value={value ?? 10}
        onChange={(value) => handleChangeValue(value) | setValue(value)}
      >
        {items.map((item) => (
          <Option
            value={item}
            key={item}
          >
            <div className="option-select-item">{item}</div>
          </Option>
        ))}
      </Select>
    </StyledPerPageSelect>
  );
};

export default React.memo(FilterItemsPerPage);