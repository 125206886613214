export const checkCookiesAndSet = (trustedCookies, setCookie, cookies) => {
  let params = (new URL(document.location)).searchParams;

  trustedCookies.forEach(cookie => {
    let arr = cookies[cookie] ? cookies[cookie] : [];

    if (params.get(cookie) && !arr.find((item => item.token === params.get(cookie)))) {
      arr.push({"token" : params.get(cookie)});
      setCookie(cookie,arr, { path: "/" });
    }
  });
};