import styled from "styled-components";

export const StyledNavigationWrapper = styled.nav`
  flex-grow: 1;
  z-index: 10;
  @media screen and (max-width: 576px) {
    max-width: 50px;
    width: 100%;
    order: 1;
    flex-grow: inherit;
  }
`;

export const StyledNavigationContent = styled.div`
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 0;
    max-height: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: saturate(180%) blur(20px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow-y: hidden;
    transition: background .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1), height .5s cubic-bezier(0.52, 0.16, 0.24, 1);
    ${({ show }) => show && show && `
        height: 100vh;
        transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1),height .5s cubic-bezier(0.52, 0.16, 0.24, 1);
    `};

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(45deg, rgba(255,255,255,0) 0%, rgb(255 255 255) 35%, rgb(255 255 255) 100%);
    }
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  justify-content: center;

  .sidebar-logout {
    display: none;
  }

  li {
    position: relative;
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
    transition: all .3s ease;
    color: #333333;
    cursor: pointer;
    filter: drop-shadow(0.337214px 0.337214px 6.74428px rgba(155, 104, 228, 0.2));

    .nav-title {
      font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : "20px"};
    }
    
    .nav-icon {
      display: none;
    }

    a {
      position: relative;
      z-index: 10;
    }

    &:after {
      content: '';
      background: rgba(155, 104, 228, 0.2);
      filter: blur(5px);
      position: absolute;
      height: 20px;
      width: 100%;
      transition: all 0.2s;
      left: 0;
      opacity: 0;
    }

    &:hover:after {
      transition: all 0.2s;
      opacity: 1;
    }
  }

  .not-authenticated-actions {
    display: none;
  }

  @media screen and (max-width: 576px) {
    padding: 10px 0;
    margin-top: ${({ inViewport }) => inViewport ? "135px" : "100px"};
    flex-direction: column;
    justify-content: unset;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    .sidebar-logout {
      display: block;
    }

    li {
      width: 100%;
      padding: 10px 25px;
      text-align: center;
      justify-content: start;
      filter: none;
      
      .nav-title {
        font-size: 14px;
      }

      span {
        color: #A9A9A9;
      }

      .nav-icon {
        width: 20px;
        height: 20px;
        display: contents;
        font-size: 18px;
        text-align: center;
      }

      &:after {
        content: none;
      }

      &:hover {
        transition: all .3s ease;
        background-color: #FAF7FF;

        span {
          color: #9B68E4;
        }
      }

      a {
        width: 100%;
        display: inline-flex;
        align-items: center;
        gap: 15px;
      }
    }

    .not-authenticated-actions {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;

      .login-button {
        padding: 2px 0;
        border: none;
        outline: none;
       
        span {
          color: #000000;
        }

        &__icon {
          display: none;
        }
        &:hover {
          box-shadow: none;
        }
      }

      .registration-button {
        border: none;
        padding: 8px 21px;
      }

      .registration-button {
        border-radius: 10px;
        background: ${({ theme }) => theme.bodyColorText};
      }
    }
  }
`;

export const StyledMobileNavigation = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;
  z-index: 5;
  div {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333333;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  .navbar-top {
    top: 3px;
  }
  .navbar-middle {
    top: 12px;
  }
  .navbar-bottom {
    top: 21px;
  }
  ${({ show }) => show && show && `
    .navbar-top {
      top: 12px;
      transform: rotate(135deg);
    }
    .navbar-middle {
      opacity: 0;
      left: -60px;
    }
    .navbar-bottom {
      top: 12px;
      transform: rotate(-135deg);
    }
  `};
  
  @media screen and (max-width: 576px) {
    display: block;
  }
`;