
export const purpleTheme = {
  body: "#FBFBFB",
  bodyColorText: "#09080B",
  main: "#9B68E4",
  subMainColor: "#8474A0",
  neutralPrimary: "#FAF7FF",
  switchPosition: "1px",
  emptyProjectIconBg: "#C8A9F1",
  typography: {
    main: "#09080B",
    sub: "#A9A9A9"
  },
  userPhoto: {
    iconRemove: "#A9A9A9"
  },
  button: {
    bgColor: "#9F65E9",
    border: "#9F65E9",
    borderColor: "",
    hover: {
      backgroundHover: "#8e4ce3",
      backgroundMainHover: "rgba(142, 76, 227, 0.1)",
      border: "#8e4ce3"
    }
  },
  dropDown: {
    buttonColor: "#09080B",
    buttonColorHover: "#8474A0",
    buttonBackgroundHover: "#FAF7FF",
    buttonMainBackgroundHover: "#E6E1ED",
    buttonLabel: "#9B68E4",
    buttonIcon: "#9B68E4"
  },
  rcTabs: {
    "rc-tabs-ink-bar": {
      background: "#9B68E4"
    },
    "rc-tabs-tab-active": {
      color: "#9B68E4"
    }
  },

  navigation: {
    backgroundMobile: "#9B68E4",
    list: {
      hover: "#ccc8d3"
    }
  },
  sidebar: {
    iconMobile: {
      hover: "#ccc8d3"
    },
    sidebarBtn: {
      color: "#9B68E4",
      current: {
        backgroundCurrent: "#FAF7FF"
      },
      list: {
        hover: {
          backgroundHover: "#FAF7FF"
        }
      }
    },
    sidebarInfoUser: {
      color: "#9B68E4"
    }
  },
  input: {
    labelColor: "#A9A9A9",
    borderColor: "#CFCFCF",
    backgroundColor: "#FFFFFF"
  },
  select: {
    labelColor: "#A9A9A9",
    borderColor: "#9B68E4",
    backgroundColor: "#FFFFFF",
    bgSelected: "#8474A0",
    hover: {
      bgSelect: "#9B68E4"
    }
  },
  datePicker: {
    borderColor: "#CFCFCF",
    backgroundColor: "#FFFFFF",
    currentDateTextColor: "#FFFFFF",
    currentDateBgColor: "#8474A0",
    hover: {
      selectDay: "#9B68E4"
    }
  },
  countBlock: {
    color: "#FFFFFF",
    backgroundColor: "#8366AC"
  },

  "rc-pagination": {
    border: "#9B68E4",
    backgroundColorActive: "#9B68E4"
  },

  chat: {
    chatItem: {
      emptyProjectIconBg: "#C8A9F1",
      active: {
        backgroundColorActive: "#9B68E4",
        color: "#FFFFFF"
      },
      status: {
        inProgress: {
          backgroundColor: "linear-gradient(222.84deg, #FFE08A 37.63%, #FFFFFF 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #FFE08A 85%, #FFFFFF 150.19%)"
        },
        new: {
          backgroundColor: "linear-gradient(222.84deg, #F0FDAD 37.63%, #FFFFFF 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #F0FDAD 85%, #FFFFFF 150.19%)"
        },
        spam: {
          backgroundColor: "linear-gradient(222.84deg, #FBC8CE 37.63%, #FFECEE 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #FBC8CE 85%, #FFECEE 150.19%)"
        }
      }
    },
    chatControlPanel: {
      buttonHover: "#9B68E4",
      buttonBackgroundHover: "#FAF7FF"
    },
    ansTemplate: {
      colorTitle: "#8474A0"
    },
    inviteList: {
      colorTitle: "#8474A0"
    },
    messagerTextAreaBackgroundColor: "#F4F1F9",
    settingsChatWrapper: {
      background: "linear-gradient(195.8deg, #FFFFFF -2.63%, #F3F2F2 113.92%, #F3F2F2 113.94%)"
    }
  },

  table: {
    row: {
      hover: "#FAF7FF"
    }
  },

  projectCreate: {
    step: {
      stepItemActive: {
        background: "#FAF7FF",
        borderColor: "#9B68E4",
        textColor: "#9B68E4"
      }
    }
  }
};