import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";

import {
  StyledDelayInput,
  StyledError,
  StyledInputGroup,
  StyledInputWrapper,
  StyledLabel,
  StyledPasswordEye, StyledPrompt
} from "./styledInputGroup";

const DelayInput = ({
  id,
  label,
  name,
  type,
  value,
  className,
  debounceTimeout,
  handleChange,
  error,
  mb,
  readonly,
  prompt,
  maxWidth,
  maxInputWidth,
  ...otherProps
}) => {
  const [typePassword, setTypePassword] = useState("password");

  const changeType = () => {
    setTypePassword(typePassword === "password" ? "text" : "password");
  };

  return (
    <StyledInputGroup
      mb={mb}
      maxWidth={maxWidth}
      className={`input-group ${className}`}
      type={type}
    >
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledInputWrapper error={error && error[name]}>
        <StyledDelayInput maxInputWidth={maxInputWidth}>
          <DebounceInput
            id={id}
            type={type === "password" ? typePassword : type}
            name={name}
            value={value}
            forceNotifyByEnter={true}
            forceNotifyOnBlur={true}
            debounceTimeout={debounceTimeout}
            readOnly={readonly}
            onChange={handleChange}
            {...otherProps}
          />
        </StyledDelayInput>
        {type === "password" && <StyledPasswordEye
          className={typePassword === "password" ? "icon-eye-slash" : "icon-eye"}
          type="button"
          onClick={changeType}
        />}
      </StyledInputWrapper>
      {(error && error[name]) && <StyledError>{error[name]}</StyledError> || prompt && <StyledPrompt>{prompt}</StyledPrompt>}
    </StyledInputGroup>
  );
};

export default DelayInput;
