import routes from "./routes";
import React from "react";
import LogsPage from "../pages/logs/LogsPage";

const AccountSettings = React.lazy(() => import("../pages/account/AccountSettingsPage"));
const ProjectsPages = React.lazy(() => import("../pages/project/ProjectsPage"));
const CreateProjectsPages = React.lazy(() => import("../pages/project/CreateProjectsPage"));
const ProjectsDetailsPage = React.lazy(() => import("../pages/project/ProjectsDetailsPage"));
const ChatsPage = React.lazy(() => import("../pages/chats/ChatsPage"));
const ArchivedDialogPage = React.lazy(() => import("../pages/archivedDialog/ArchivedDialogPage"));
const CorporateChatsPage = React.lazy(() => import("../pages/corporateChats/ChatsPage"));
const UsersDetailsPage = React.lazy(() => import("../pages/users/admin/UsersDetailsPage"));
const ManagersDetailsPage = React.lazy(() => import("../pages/users/owner/ManagersDetailsPage"));
const PaymentSettingsPage = React.lazy(() => import("../pages/paymentSettings/PaymentSettingsPage"));
const FeedbacksPage = React.lazy(() => import("../pages/feedbacks/FeedbacksPage"));
const FeedbackDetailsPage = React.lazy(() => import("../pages/feedbacks/FeedbackDetailsPage"));
const AdminTariffsAndSubscriptionsPage = React.lazy(() => import("../pages/tariffsAndSubscriptions/admin/AdminTariffsAndSubscriptionsPage"));
const OwnerTariffsAndSubscriptionsPage = React.lazy(() => import("../pages/tariffsAndSubscriptions/owner/OwnerTariffsAndSubscriptionsPage"));
const OwnerBalancePage = React.lazy(() => import("../pages/balance/owner/OwnerBalancePage"));
const StatisticsProjectsPage = React.lazy(() => import("../pages/statistics/StatisticsProjectsPage"));
const StatisticsDetailsPage = React.lazy(() => import("../pages/statistics/StatisticsDetailsPage"));
const ArchiveDialogProjectsPage = React.lazy(() => import("../pages/archivedDialog/ArchiveDialogProjectsPage"));
const ArchiveDialogDetailsPage = React.lazy(() => import("../pages/archivedDialog/ArchiveDialogDetailsPage"));
const CardVerificationPage = React.lazy(() => import("../pages/cardVerification/CardVerificationPage"));
const CardVerificationDetailsPage = React.lazy(() => import("../pages/cardVerification/CardVerificationDetailsPage"));
const CardVerificationUpdatePage = React.lazy(() => import("../pages/cardVerification/CardVerificationUpdatePage"));
const AssessmentsPage = React.lazy(() => import("../pages/assessments/AssessmentsPage"));
const UsersPage = React.lazy(() => import("../pages/users/UsersPage"));

const userRoutes = [
  {
    title: "Account page",
    path: "/panel/account",
    component: AccountSettings
  },
  {
    title: "Project",
    path: "/panel/project",
    component: ProjectsPages
  },
  {
    title: "Create Project",
    path: "/panel/create-project/:id",
    component: CreateProjectsPages
  },
  {
    title: "Projects detail",
    path: "/panel/projects-details/:id",
    exact: true,
    component: ProjectsDetailsPage
  },
  {
    title: "Chats",
    path: "/panel/chats",
    component: ChatsPage
  },
  {
    title: "CorporateChats",
    path: "/panel/corporate-chats",
    component: CorporateChatsPage
  },
  {
    title: "Logs",
    path: "/panel/logs",
    exact: true,
    component: LogsPage
  },
  {
    title: "Users",
    path: "/panel/users",
    exact: true,
    component: UsersPage
  },
  {
    title: "Users-detail",
    path: "/panel/users-details/:id",
    exact: true,
    component: UsersDetailsPage
  },
  {
    title: "Managers-detail",
    path: "/panel/managers-details/:id",
    exact: true,
    component: ManagersDetailsPage
  },
  {
    title: "Payment settings page",
    path: "/panel/payment-setting",
    component: PaymentSettingsPage
  },
  {
    title: "Feedbacks list",
    path: "/panel/feedbacks",
    exact: true,
    component: FeedbacksPage
  },
  {
    title: "Feedbacks Details",
    path: "/panel/feedbacks/details/:id",
    exact: true,
    component: FeedbackDetailsPage
  },
  {
    title: "Tariffs and subscriptions settings",
    path: "/panel/tariffs-and-subscriptions-settings",
    exact: true,
    component: AdminTariffsAndSubscriptionsPage
  },
  {
    title: "My tariff and subscription",
    path: "/panel/my-tariff-and-subscription",
    exact: true,
    component: OwnerTariffsAndSubscriptionsPage
  },
  {
    title: "Balance",
    path: "/panel/balance",
    exact: true,
    component: OwnerBalancePage
  },
  {
    title: "Statistics",
    path: "/panel/statistics",
    exact: true,
    component: StatisticsProjectsPage
  },
  {
    title: "Assessments",
    path: "/panel/assessments",
    exact: true,
    component: AssessmentsPage
  },
  {
    title: "ArchivedDialog",
    path: "/panel/archived-dialog/:dialogId/:projectId",
    exact: true,
    component: ArchivedDialogPage
  },
  {
    title: "Statistics",
    path: "/panel/statistics/:id",
    exact: true,
    component: StatisticsDetailsPage
  },
  {
    title: "Card verification",
    path: "/panel/card-verification",
    component: CardVerificationPage
  },
  {
    title: "Card verification details",
    path: "/panel/card-verification-details/:id",
    exact: true,
    component: CardVerificationDetailsPage
  },
  {
    title: "Card verification edit",
    path: "/panel/card-verification-edit/:id",
    exact: true,
    component: CardVerificationUpdatePage
  },
  {
    title: "Archive Projects",
    path: "/panel/archive",
    exact: true,
    component: ArchiveDialogProjectsPage
  },
  {
    title: "Archive",
    path: "/panel/archive/:id",
    exact: true,
    component: ArchiveDialogDetailsPage
  }
];
const userRoutesConcat = userRoutes.concat(routes);

export default userRoutesConcat;
