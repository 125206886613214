import styled from "styled-components";

export const StyledDecorationElement = styled.span`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  max-width: ${({maxWidth}) => maxWidth || "100%" };
  display: ${({display}) => display || "inline" };
  text-align: ${({position}) => position ? position : 'inherit'};
  font-weight: ${({ weight }) => weight || "400"};
  font-size: ${({ size }) => size || "14px"};
  line-height: ${({ lineHeight }) => lineHeight ? `${lineHeight}px` : "22px"};
  color: ${({ color }) => color || "#09080B"};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ underline }) => underline && `text-decoration: underline`};
  ${({ wrap }) => wrap && `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`;

export const StyledParagraph = styled.p`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  b {
    padding-bottom: 7px;
  }
  b.block {
    display: block;
  }
  strong {
    font-weight: inherit;
  }
  strong.bold {
    font-weight: 700;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const StyledDocumentImage = styled.div`
  ${({center}) => center && 'margin: 0 auto;'};
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({width}) => width && `max-width: ${width}px`};
  ${({height}) => height && `height: ${height}px`};
  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledBorderComponent = styled.div`
  ${({ position }) => position && `position: ${position}`};
  width: 100%;
  position: absolute;
  ${({bTop}) => bTop && `
    border-top: 1px solid #CFCFCF;
  `}
  ${({bBottom}) => bBottom && `
    border-bottom: 1px solid #CFCFCF;
  `}
  left: 0;
`