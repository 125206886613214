import styled from "styled-components";

const type = {
  type: ""
};

const changeType = (type, border) => {
  switch (type) {
    case "error" :
      return `
        ${border && "border: 1px solid #FE6A74"};
        background-color: #FFECEE;
        &:before {
          content: '\\e914';
          color: #FE6A74;
          background-color: #fbc8ce;
        }
      `;
    case "success" :
      return `
        ${border && "border: 1px solid #4fba6e"};
        background-color: #e3f7e4;
        &:before {
          content: '\\e922';
          color: #4fba6e;
          background-color: #d2efda;
        }
      `;
    case "info" :
      return `
        ${border && "border: 1px solid #3286d9"};
        background-color: #EDF9FF;
        &:before {
          content: '\\e942';
          color: #3286d9;
          background-color: #d2eefc;
        }
      `;
    case "warning" :
      return `
        ${border && "border: 1px solid #efc100"};
        background-color: #fdf3c9;
        &:before {
          content: '\\e97c';
          display: block;
          color: #fdf3c9;
          background-color: #efc100;
        }
      `;
    default :
      return `
        ${border && "border: 1px solid #9B68E4"};
        background-color: rgba(155,104,228,0.3);
        &:before {
          content: '\\e922';
          color: #fdf3c9;
          background-color: rgba(155,104,228,0.5);
        }
      `;
  }
};

export const StyledAlertWrapper = styled("div", type)`
  ${({ center }) => center && "max-width: 1110px"};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  max-width: ${({maxWidth}) => maxWidth ? maxWidth : "100%"};
  padding: 15px 15px 15px 55px;
  color: #202020;
  border-radius: 7px;
  position: relative;
  
  &:before {
    width: 25px;
    height: 25px;
    font-size: 16px;
    font-family: 'default-icons', serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    left: 15px;
  }
  ${({ type, border }) => changeType(type, border)}
`;