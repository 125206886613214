import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";

const HelmetContainer = ({ title, description, children, page }) => {

  const arrayExamPage = ["Home"];
  const examinationPage = (thisPage) => {
    if (arrayExamPage.some(item => item === thisPage)) {
      return title;
    }

    return `${title} - Text me`;
  };

  const examinationPageMemo = useMemo(() => examinationPage(page), [title]);

  return (
    <>
      <Helmet>
        <title>{examinationPageMemo}</title>
        {description && <meta
          name="description"
          content={description}
        />}
        <link
          rel="canonical"
          href={window.location.href}
        />
      </Helmet>
      {children}
    </>
  );
};

export default HelmetContainer;