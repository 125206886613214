export const blueTheme = {
  body: "#FBFBFB",
  bodyColorText: "#09080B",
  main: "#1725A0",
  subMainColor: "#2B3278",
  neutralPrimary: "#F7FAFF",
  switchPosition: "calc(100% - 19px)",
  emptyProjectIconBg: "#6E77D0",
  typography: {
    main: "#09080B",
    sub: "#A9A9A9"
  },
  userPhoto: {
    iconRemove: "#A9A9A9"
  },
  button: {
    bgColor: "#1725A0",
    border: "#1725A0",
    borderColor: "",
    hover: {
      backgroundHover: "#0b1466",
      backgroundMainHover: "rgba(11, 20, 102, 0.1)",
      border: "#0b1466"
    }
  },
  dropDown: {
    buttonColor: "#09080B",
    buttonColorHover: "#2B3278",
    buttonBackgroundHover: "#F7FAFF",
    buttonMainBackgroundHover: "#F1F4F9",
    buttonLabel: "#1725A0",
    buttonIcon: "#1725A0"
  },
  rcTabs: {
    "rc-tabs-ink-bar": {
      background: "#1725A0"
    },
    "rc-tabs-tab-active": {
      color: "#1725A0"
    }
  },
  navigation: {
    backgroundMobile: "#1725A0",
    list: {
      hover: "#9299d5"
    }
  },
  sidebar: {
    iconMobile: {
      hover: "#9299d5"
    },
    sidebarBtn: {
      color: "#1725A0",
      current: {
        backgroundCurrent: "#182ac70d"
      },
      list: {
        hover: {
          backgroundHover: "#182ac70d"
        }
      }
    },
    sidebarInfoUser: {
      color: "#1725A0"
    }
  },
  input: {
    labelColor: "#CFCFCF",
    borderColor: "#CFCFCF",
    backgroundColor: "#FFFFFF"
  },
  select: {
    labelColor: "#A9A9A9",
    borderColor: "#0b1466",
    backgroundColor: "#FFFFFF",
    bgSelected: "#0b1466",
    hover: {
      bgSelect: "#1725A0"
    }
  },
  datePicker: {
    borderColor: "#CFCFCF",
    backgroundColor: "#FFFFFF",
    currentDateTextColor: "#FFFFFF",
    currentDateBgColor: "#0b1466",
    hover: {
      selectDay: "#1725A0"
    }
  },
  countBlock: {
    color: "#FFFFFF",
    backgroundColor: "#2B3278"
  },

  "rc-pagination": {
    border: "#1725A0",
    backgroundColorActive: "#1725A0"
  },

  chat: {
    chatItem: {
      emptyProjectIconBg: "#6E77D0",
      active: {
        backgroundColorActive: "#1725A0",
        color: "#FFFFFF"
      },
      status: {
        inProgress: {
          backgroundColor: "linear-gradient(222.84deg, #FFE08A 37.63%, #FFFFFF 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #FFE08A 85, #FFFFFF 150.19%)"
        },
        new: {
          backgroundColor: "linear-gradient(222.84deg, #F0FDAD 37.63%, #FFFFFF 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #F0FDAD 85%, #FFFFFF 150.19%)"
        },
        spam: {
          backgroundColor: "linear-gradient(222.84deg, #FBC8CE 37.63%, #FFECEE 150.19%)",
          backgroundColorHover: "linear-gradient(222.84deg, #FBC8CE 85%, #FFECEE 150.19%)"
        }
      }
    },
    chatControlPanel: {
      buttonHover: "#1725A0",
      buttonBackgroundHover: "#F7FAFF"
    },
    ansTemplate: {
      colorTitle: "#0b1466"
    },
    inviteList: {
      colorTitle: "#0b1466"
    },
    messagerTextAreaBackgroundColor: "#F1F4F9",
    settingsChatWrapper: {
      background: "linear-gradient(195.8deg, #FFFFFF -2.63%, #F3F2F2 113.92%, #F3F2F2 113.94%)"
    }
  },
  table: {
    row: {
      hover: "#F7FAFF"
    }
  },
  projectCreate: {
    step: {
      stepItemActive: {
        background: "#6E77D0",
        borderColor: "#6E77D0",
        textColor: "#1725A0"
      }
    }
  }
};