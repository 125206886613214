import React from "react";
import Can from "../can/Can";
import ChatsCounter from "./counters/ChatsCounter";

import { StyledSidebarItem, StyledSidebarLink } from "./styledSidebar";
import FeedbackCounter from "./counters/FeedbackCounter";
import CorporateChatsCounter from "./CorporateChatsCounter";

const SidebarItem = ({ role, perform, as, to, icon, linkTitle, handleChange, exact, badge, disabled, visible }) => {

  const renderCounter = () => {
    switch (linkTitle) {
      case "Chats":
        return <ChatsCounter linkTitle={linkTitle} visible={visible} />;
      case "Feedbacks":
        return <FeedbackCounter linkTitle={linkTitle} />;
      case "Team chats":
        return <CorporateChatsCounter linkTitle={linkTitle} visible={visible} />;
    }
  };

  return (
    <Can
      role={role}
      perform={perform}
      yes={() => (
        <StyledSidebarItem badge={badge} disable={disabled}>
          <StyledSidebarLink
            exact={exact}
            as={as}
            to={to}
            onClick={handleChange}
            className="sidebar-link"
            activeClassName="sidebar-link_current"
          >
            <div className="sidebar-link__icon">
              <span className={`icon-${icon}`} />
            </div>
            <div className="sidebar-link__title">
              {linkTitle}
              {renderCounter()}
            </div>
          </StyledSidebarLink>
        </StyledSidebarItem>
      )}
    />
  );
};

export default SidebarItem;