import { adminRoutes, managerRoutes, ownerRoutes } from "./routesSidebar";
import { roles } from "../../../utils/consts";
const sidebarRoutes = {
  ROLE_ADMIN: adminRoutes,
  ROLE_MANAGER: managerRoutes,
  ROLE_OWNER: ownerRoutes
};

/*generateSidebarRouter - функція була створена щоб змінити масив при виконанні умови, функці потрібна для дізейблу табів в сайт барі
  якщо у користувача немає чи не було створених проектів.
* */

const generateSidebarRouter = (isCreatedSubAndProject, role) => {
  const someArrayDisabled = ["Chats", "Managers", "Statistics", "Corporate Chats"];

  if (isCreatedSubAndProject) {
    return sidebarRoutes[role.toString()];
  }

  if (!isCreatedSubAndProject) {
    const newSidebarRoutes = sidebarRoutes[role.toString()].map((item) => {
      if (someArrayDisabled.some(element => element === item.title) && role.toString() === roles.OWNER) {
        return {
          ...item,
          disabled: true
        };
      }
      return item;
    });
    return newSidebarRoutes;
  }
};

export {
  generateSidebarRouter
};