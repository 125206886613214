import { StyledCol, StyledTableRow } from "../../styles/styledTable";
import { TimestampToDateTime } from "../../../utils/timestampToDate";
import { roles } from "../../../utils/consts";
import { userRole } from "../../../utils/userRole";
import CopyToClipboard from "../../elements/copyValueToClipboard/CopyToClipboard";

const logsItem = ({ value }) => {

  return (
    <StyledTableRow
      col="6"
      cursor="pointer"
      className="logs-table__row"
    >
      <StyledCol data-title="User">
        <CopyToClipboard
          value={value.context?.user}
        />
      </StyledCol>
      <StyledCol data-title="Role">
        {value.context?.role.filter(role => Object.values(roles).includes(role)).map((role) => (
            userRole(role) + "\n"
          )
        )}
      </StyledCol>
      <StyledCol data-title="Level">
        {value.level_name}
      </StyledCol>
      <StyledCol data-title="Action">
        {value.message}
      </StyledCol>
      <StyledCol data-title="Date">
        {TimestampToDateTime(value.datetime)}
      </StyledCol>
      <StyledCol data-title="IP">
        <CopyToClipboard
          value={value.context?.ip}
        />
      </StyledCol>


    </StyledTableRow>
  );
};

export default logsItem;