import PageSpinner from "../../elements/spinner/PageSpinner";
import React from "react";
import AlertMessage from "../../elements/alert/Alert";
import { StyledCol, StyledTable, StyledTableBody, StyledTableHead } from "../../styles/styledTable";
import LogsItem from "./LogsItem";

const LogsList = ({ logs }) => {
  if (!logs) {
    return <PageSpinner
      position="center"
      pt="20"
      pb="20"
    />;
  }

  if (!logs || logs.length === 0) {
    return <AlertMessage
      type="info"
      message="There are no any logs"
    />;
  }

  return (
    <StyledTable
      className="logs-table"
    >
      <StyledTableHead
        col="6"
        className="logs-table__head"
      >
        <StyledCol>
          User
        </StyledCol>
        <StyledCol>
          Role
        </StyledCol>
        <StyledCol>
          Level
        </StyledCol>
        <StyledCol>
          Action
        </StyledCol>
        <StyledCol>
          Date
        </StyledCol>
        <StyledCol>
          IP
        </StyledCol>
      </StyledTableHead>
      <StyledTableBody>
        {logs && logs.map((value, key) => (
          <LogsItem
            value={value}
            key={key}
          />
        ))}
      </StyledTableBody>
    </StyledTable>
  );
};
export default LogsList;