import React, { memo, useEffect } from "react";
import useSound from "use-sound";
import { soundsTypes } from "../../../utils/consts";

import soundSprite from "../../../assets/sounds/sound-sprite-v1_1.wav";

const Sound = ({ sounds, counter }) => {
  const [play] = useSound(soundSprite, {
    sprite: {
      new: [0, 1250],
      inbox: [2500, 250],
      sent: [3700, 250]
    },
    volume: 0.6,
    interrupt: true
  });

  useEffect(() => {
    if (counter !== 0) {
      switch (sounds[sounds.length - 1]?.type) {
        case soundsTypes.NEW:
          play({ id: soundsTypes.NEW });
          break;
        case soundsTypes.INBOX:
          play({ id: soundsTypes.INBOX });
          break;
        case soundsTypes.SENT:
          play({ id: soundsTypes.SENT });
          break;
      }
    }
  }, [counter]);

  return null;
};

export default memo(Sound);