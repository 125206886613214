import React, { useContext, useEffect, useState } from "react";
import { getMercureTopics } from "../../../../utils/getMercureTopics";
import { browsers, mercureTimeOut, mercureUrl, responseStatus, standardErrorsWay, chatType } from "../../../../utils/consts";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../../../App";
import { StyledBadgeNotification } from "../styledSidebar";
import { parseAxiosError } from "../../../../utils/response";

const ChatsCounter = ({ linkTitle, className, visible }) => {
  const { user } = useContext(AuthContext);

  const [counter, setCounter] = useState();
  const [cookies, setCookie] = useCookies(["mercureAuthorization"]);

  const fetchNewDialogsCounter = () => {
    if (chatType.some(element => element === linkTitle)) {
      axios.get("/api/new-dialogs-count?type=" + linkTitle,
        userAuthenticationConfig()).then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setCounter(response.data);
        }
      }).catch(error => {
        parseAxiosError(eval(standardErrorsWay));
      });
    }
  };

  let esSideBarNewDialogsCounter = null;
  let mercureSideBarNewDialogsCounterTime = null;

  const mercureSideBarNewDialogsCounter = () => {
    clearTimeout(mercureSideBarNewDialogsCounterTime);

    mercureSideBarNewDialogsCounterTime = setTimeout(() => {
      esSideBarNewDialogsCounter.close();
      esSideBarNewDialogsCounter = mercureSideBarNewDialogsCounter();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("new-dialogs-counter", [{ id: user.userId }]),
      { path: "/" });

    esSideBarNewDialogsCounter = new EventSource(mercureUrl, { withCredentials: true });

    esSideBarNewDialogsCounter.onmessage = (event) => {
      fetchNewDialogsCounter();
    };

    esSideBarNewDialogsCounter.onerror = (event) => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esSideBarNewDialogsCounter = mercureSideBarNewDialogsCounter();
    };

    return esSideBarNewDialogsCounter;
  };

  useEffect(() => {
    if (!visible) {
      return;
    }

    fetchNewDialogsCounter();

    esSideBarNewDialogsCounter = mercureSideBarNewDialogsCounter();

    return () => {
      if (esSideBarNewDialogsCounter) {
        esSideBarNewDialogsCounter.close();
      }

      clearTimeout(mercureSideBarNewDialogsCounterTime);
    };

  }, [visible]);

  if (!counter || counter === 0) {
    return <></>;
  }

  return (
    <StyledBadgeNotification className={className}>
      {counter}
    </StyledBadgeNotification>
  );
};

export default ChatsCounter;
