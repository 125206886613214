import styled from "styled-components";
import { motion } from "framer-motion";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (color, border, theme) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? `${theme.button.bgColor}` : "#fff"};
        background-color: ${border ? "transparent" : `${theme.button.bgColor}`};
        border: 1px solid ${theme.button.bgColor};
        &:hover {
          background-color: ${border ? theme.button.hover.backgroundMainHover : theme.button.hover.backgroundHover};
          border: 1px solid ${border ? theme.button.hover.border : theme.button.hover.border};
        }
    `;
    case "neutral":
      return `
        color: ${border ? "#323233" : "#fff"};
        background-color: ${border ? "transparent" : "#323233"};
        border: 1px solid #323233;
        &:hover {
          background-color: ${border ? "rgba(55, 55, 55, 0.15);" : "#4e4e4e"};
          border: 1px solid #4e4e4e;
        }
    `;
    case "success":
      return `
        color: ${border ? "#ACD07C" : "#fff"};
        background-color: ${border ? "transparent" : "#ACD07C"};
        border: 1px solid #ACD07C;
        &:hover {
          background-color: ${border ? "rgba(46, 210, 38, 0.1);" : "#ACD07C"};
          border: 1px solid #ACD07C;
        }
     `;
    case "danger":
      return `
        color: ${border ? "#D25C65" : "#fff"};
        background-color: ${border ? "transparent" : "#D25C65"};
        border: 1px solid #D25C65;
        &:hover {
          background-color: ${border ? "rgba(255, 77, 89, 0.1);" : "#D25C65"};
          border: 1px solid #D25C65;
        }
      `;
    case "warning":
      return `
        color: #202020;
        background-color: ${border ? "transparent" : "#ffeb3f"};
        border: 1px solid #ffeb3f;
        &:hover {
          background-color: ${border ? "rgba(254, 217, 126, 0.2);" : "#ffe23f"};
          border: 1px solid #ffe23f;
        }
     `;
    case "info":
      return `
        color: ${border ? "#3286d9" : "#fff"};
        background-color: ${border ? "transparent" : "#3286d9"};
        border: 1px solid #3286d9;
        &:hover {
          background-color: ${border ? "rgba(1, 125, 225, 0.1);" : "#379cff"};
          border: 1px solid #379cff;
        }
     `;
    case "white":
      return `
        color: ${border ? "#fff" : "#333333"};
        background-color: ${border ? "transparent" : "#fff"};
        border: 1px solid #fff;
        &:hover {
          background-color: ${border ? "rgba(255, 255, 255, 0.15);" : "#fff"};
          border: 1px solid #fff;
        }
      `;
    case "transparent":
      return `
        color: #656565;
        background: transparent;
        border: none;
        &:hover{
          background: rgba(0,0,0,0.1);
        }
      `
        ;
    default:
      return `
        color: #323233;
        background: transparent;
        border: 1px solid #323233;
        &:hover {
          background-color: rgba(55, 55, 55, 0.15);
          border: 1px solid #4e4e4e;
        }
      `;
  }
};

export const StyledButton = styled(motion.button, colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  ${({ position }) => position && `position: ${position}`};
  padding: ${({ figure }) => figure === "circle" ? "12px" : "8px 16px"};
  font-weight: ${({ weight }) => weight === "bold" ? "700" : "500"};
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "7px"};
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};

  ${({ color, border, theme }) => styleButton(color, border, theme)}
  &:hover {
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: scale(0.948) translateZ(0);
  }
`;