import styled from "styled-components";

export const StyledScrollTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    background-color: #ECEEEE;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ECEEEE;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid #ECEEEE;
    background-color: #c8b19b;
    border-radius: 12px;
  }
`;

export const StyledTable = styled.div`
  font-size: ${({ size }) => size + "px"};
  ${({ width }) => width && `min-width: ${width}px`};
  ${({ width }) => width && "width: 100%"};
  ${({ width }) => width && "overflow-x: hidden;"};
`;

export const StyledTableHead = styled.div`
  padding: 15px;
  color: #A9A9A9;
  font-size: 14px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;
  font-weight: 400;
  @media screen and (max-width: 992px) {
    display: ${({ scroll }) => scroll === "auto" ? "grid" : "none"};
  }
`;

export const StyledColHead = styled.div`
  ${({ position }) => position && `
    text-align: ${position}
  `};
`;

export const StyledTableBody = styled.div`
  word-break: break-all;
`;

export const StyledTableRow = styled.div`
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  padding: 10px 15px;
  color: #09080B;
  font-size: 14px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;
  align-items: center;
  border-top: 1px solid #CFCFCF;
  &:last-child {
    border-bottom: 1px solid #CFCFCF;
  }
  &:hover {
    background: ${({ theme }) => theme.table.row.hover};
  }

  [data-title] {
    &::before {
      color: ${({ theme }) => theme.typography.sub};
      font-weight: 500;
    }
  }

  ${({ cursor }) => cursor && `
    cursor: ${cursor};
    &:hover {
      background: ${({ theme }) => theme.table.row.hover};
    }
  `};

  @media screen and (max-width: 992px) {
    grid-template-rows: ${({ scroll }) => scroll === "auto"
  ? "1fr"
  : `repeat(${({ col }) => col}, minmax(50px, auto))`};
    grid-template-columns: ${({ scroll }) => scroll === "auto" ? `repeat(${({ col }) => col}, 150px)` : "1fr"};
    ${({ scroll }) => scroll === "auto" ? `
       & > div {
         &:before {display: none};
        }
    ` : `
      margin-bottom: 20px;
      padding: 15px 20px;
      position: relative;
      border-bottom: 1px solid #F1F1F1;
      &:nth-child(2n) {
        background-color: #F7F8F8;
      }
      & > div {
        padding: 0 0 0 50%;
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
      .table-actions {
        padding: 0;
        margin-top: 0px !important;
        position: absolute;
        top: 7px;
        right: 7px;
        z-index: 1;
        &:before {
          display: none !important;
        }
      }
    `};
  }

  @media screen and (max-width: 576px) {
    & > div {
      padding: 0;
    }
  }

  @media screen and (max-width: 576px) {
    ${({ scroll }) => scroll === "auto" ? `
    ` : `
      margin-bottom: 15px;
      padding: 12px 10px;
      & > div {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    `};
  }
`;

export const StyledCol = styled.div`
  ${({ inline }) => inline ? `
    display: inline-flex;
    justify-content: center;
  ` : `display: "grid";`}
  align-items: center;
  position: relative;
  &:before {
    content: attr(data-title) '';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.4px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    max-width: calc(50% - 15px);
    @media screen and (max-width: 992px) {
      display: block;
    }
    @media screen and (max-width: 576px) {
      position: static;
      max-width: none;
    }
  }
`;