const eventBus = {
  state: {},

  setState (param, value) {
    this.state = { [param]: value };
  },
  getState (param) {
    return this.state[param];
  },

  on (event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch (event, data, stateName = null) {

    if (stateName && this.getState(stateName)) {
      return;
    }
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove (event, callback) {
    document.removeEventListener(event, callback);
  }
};

export default eventBus;