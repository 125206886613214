import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { closableNotification } from "../../notification/ClosableNotification";

import { StyledCopyToClipboardWrapper } from "./styledCopyToClipboard";

const CopyValueToClipboard = ({ value, link, noWrap }) => {

  return (
    <StyledCopyToClipboardWrapper
      className="copy-to-clipboard"
      noWrap={noWrap}
    >
      <CopyToClipboard
        text={value}
        onCopy={() => {
          closableNotification("Copied", "success");
        }}
      >
        <p title="Copied">
          {link || value}
          <span className="icon-copy-alt" />
        </p>
      </CopyToClipboard>
    </StyledCopyToClipboardWrapper>
  );
};

export default CopyValueToClipboard;