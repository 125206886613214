import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledLogoWrapper = styled(motion.div)`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  filter: drop-shadow(0.337214px 0.337214px 6.74428px rgba(0, 0, 0, 0.2));
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    transition: all .3s ease;
  }
`;