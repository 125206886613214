import styled from "styled-components";

export const StyledSelect = styled.div`
  ${({ width }) => width && `max-width: ${width}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  cursor: pointer;

  .custom-select, .custom-multiselect {
    z-index: 2;
    width: 100%;
    color: ${({ theme }) => theme.bodyColorText};
    border: 1px solid #CFCFCF;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.select.backgroundColor};
    overflow: hidden;

    .option-select-item_with-img {
      [class^='icon-'], [class*='icon-'] {
        padding-right: 20px;
        font-size: 22px;
      }

      img {
        height: 22px;
        width: 22px;
        margin-right: 20px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .rc-select-open {
    border: 1px solid ${({ theme }) => theme.select.borderColor};

    .rc-select-arrow {
      .rc-select-arrow-icon {
        &:before {
          color: ${({ theme }) => theme.select.borderColor};
        }
      }
    }
  }

  .custom-multiselect {
    position: relative;
    .rc-select-selector {
      position: relative;
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      max-width: 100%;
      .rc-select-selection-placeholder {
        padding: 6px 7px;
      }
      .rc-select-selection-overflow-item {
        padding-left: 10px;
      }
      .rc-select-selection-search {
        position: relative;
        max-width: 100%;
        margin-inline-start: 5px;

        .rc-select-selection-search-input {
          width: 100%;
          min-width: 4.1px;
          margin: 0;
          padding: 0;
          outline: none;
          appearance: none;
        }
      }

      .rc-select-selection-search-mirror {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        white-space: pre;
        visibility: hidden;
      }
    }

    .rc-select-selection-item {
      margin: 5px;
      padding: 5px;
      color: #FFFFFF;
      display: inline-flex;
      background-color: ${({ theme }) => theme.select.bgSelected};
      border: none;
      border-radius: 5px;

      &:hover {
        background-color: ${({ theme }) => theme.select.hover.bgSelect};
      }

      .rc-select-selection-item-content {
        width: 100%;
      }
    }

    .rc-select-selection-search {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .rc-select-selection-search-input {
        height: 38px;
        padding: 0 10px;
        color: ${({ theme }) => theme.text};
        background-color: transparent;
      }
    }

    .option-select-item {
      width: 100%;
    }
  }

  .custom-select {
    .option-select-item {
      width: calc(100% - 50px);
      color: ${({ theme }) => theme.bodyColorText};
      display: flex;
      align-items: center;
      text-transform: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .rc-select-arrow {
    height: 100%;
    right: 20px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;

    .rc-select-arrow-icon {
      width: 14px;
      height: 14px;
      margin: 0;
      border: none;
      display: inline-flex;
      position: relative;
      top: 0;
      left: 0;

      &:before {
        content: '\\e91b';
        height: 14px;
        width: 14px;
        color: #CFCFCF;
        font-size: 14px;
        font-family: 'default-icons', serif;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .rc-select-selection-item-remove-icon {
    margin-left: 5px;
    color: #fff;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  .rc-select-selection-search {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .rc-select-selection-search-input {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      background-color: transparent;
      border: none;
    }
  }

  .rc-select-open .rc-select-arrow .rc-select-arrow-icon:before {
    content: '\\e91e';
  }
`;

export const StyledSelectLabel = styled.label`
  padding-bottom: 3px;
  color: ${({ theme }) => theme.select.labelColor};
  font-size: 14px;
  text-align: left;
  display: inline-flex;

  &:first-letter {
    text-transform: uppercase;
  }
`;