import styled from "styled-components";

export const StyledDatepicker = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  display: grid;
  grid-template-columns: 100%;
  .react-date-picker__wrapper {
    padding: 3.5px 20px;
    background-color: ${({theme}) => theme.datePicker.backgroundColor};
    border: 1px solid ${({theme}) => theme.datePicker.borderColor};
    border-radius: 25px;
    cursor: pointer;
    z-index: 5;
    input, span {
      height: 100%;
      display: inline-flex;
      color: ${({theme}) => theme.bodyColorText};
      font-size: 14px;
    }
    button {
      height: 27px;
      width: 27px;
      margin: 3px;
      padding: 5px 3px;
      background-color: transparent;
      border: 1px solid ${({theme}) => theme.main};
      border-radius: 3px;
      outline: none;
      svg {
        height: 14px;
        stroke: ${({theme}) => theme.main};
      }
      &:hover, &:focus {
        background-color: ${({theme}) => theme.neutralPrimary};
        svg {
          stroke: ${({theme}) => theme.main} !important;
        }
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .react-date-picker__calendar {
    z-index: 15;
  }
  .react-calendar {
    border: 1px solid transparent;
    box-shadow: 0 1px 8px rgb(0 0 0 / 20%);
    .react-calendar__navigation__arrow,
    .react-calendar__navigation__label,
    .react-calendar__tile {
      &:hover {
        color: #fff;
        background-color: ${({theme}) => theme.datePicker.hover.selectDay};
        border-radius: 3px;
      }
    }
    .react-calendar__month-view__days__day--weekend {
      color: #f44336;
    }
    .react-calendar__tile--now, .react-calendar__tile--active {
      border-radius: 3px;
    }
    .react-calendar__tile--now {
      color: ${({theme}) => theme.datePicker.currentDateTextColor};
      background-color: ${({theme}) => theme.datePicker.currentDateBgColor};
    }
    .react-calendar__tile--active {
      color: #fff;
      background-color: ${({theme}) => theme.datePicker.hover.selectDay};;
    }
    .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
      color: #fff;
      background-color: #323233;
    }
    .react-calendar__navigation button[disabled] {
      background-color: inherit;
      opacity: 0.5;
    }
  }
`;

export const StyledDatepickerLabel = styled.label`
  padding-bottom: 3px;
  color: ${({theme}) => theme.input.labelColor};
  font-size: 14px;
  text-align: left;
  display: inline-flex;
  &:first-letter {
    text-transform: uppercase;
  }
`;