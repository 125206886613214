import { DateTime } from "luxon";

const DateToTimestamp = (date) => {
  return date ? (Date.parse(date) / 1000).toString() : "";
};

const TimestampToDate = (date) => {
  return date ? new Date(date * 1000) : "";
};

const TimestampToDateFormat = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy");
};

const TimestampToTimeFormat = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("HH:mm");
};

const TimestampToDateTime = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy HH:mm");
};

const DateToTimestampStart = (date) => {
  return date ? (new Date(date).setHours(0, 0, 0) / 1000) : "";
};

const DateToTimestampEnd = (date) => {
  return date ? (new Date(date).setHours(23, 59, 59) / 1000) : "";
};

const DateToStringFormat = (date) => {
  return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export {
  DateToTimestamp,
  TimestampToDate,
  TimestampToDateTime,
  TimestampToDateFormat,
  TimestampToTimeFormat,
  DateToTimestampStart,
  DateToTimestampEnd,
  DateToStringFormat
};
