import React from "react";
import { navbar } from "../../../rbac-consts";

const userRoutes = [
  {
    title: "Users",
    path: "/panel/users",
    perform: navbar.USERS,
    icon: "users"
  },
  {
    title: "Chats",
    path: "/panel/chats",
    perform: navbar.CHAT,
    icon: "chat"
  },
  {
    title: "Team chats",
    path: "/panel/corporate-chats",
    perform: navbar.CORPORATE_CHAT,
    icon: "communication"
  },
  {
    title: "Projects",
    path: "/panel/project",
    perform: navbar.PROJECT,
    icon: "web"
  },
  {
    title: "Managers",
    path: "/panel/users",
    perform: navbar.MANAGERS,
    icon: "users"
  },
  {
    title: "Tariffs and subscriptions settings",
    path: "/panel/tariffs-and-subscriptions-settings",
    perform: navbar.ADMIN_TARIFFS_AND_SUBSCRIPTIONS,
    icon: "shopping-bag"
  },
  {
    title: "My tariff and subscription",
    path: "/panel/my-tariff-and-subscription",
    perform: navbar.OWNER_TARIFFS_AND_SUBSCRIPTIONS,
    icon: "shopping-bag"
  },
  {
    title: "Statistics",
    path: "/panel/statistics",
    perform: navbar.STATISTICS,
    icon: "statistics"
  },
  {
    title: "Feedbacks",
    path: "/panel/feedbacks",
    perform: navbar.FEEDBACKS,
    icon: "mail"
  },
  {
    title: "Payment settings",
    path: "/panel/payment-setting",
    perform: navbar.PAYMENT_SETTINGS,
    icon: "dollar"
  },
  {
    title: "Logs",
    path: "/panel/logs",
    perform: navbar.LOGS,
    icon: "history"
  },
  {
    title: "Card verification",
    path: "/panel/card-verification",
    perform: navbar.CARD_VERIFICATION,
    icon: "web"
  }
];

const adminRoutes = [
  {
    title: "Users",
    path: "/panel/users",
    perform: navbar.USERS,
    icon: "users"
  },
  {
    title: "Projects",
    path: "/panel/project",
    perform: navbar.PROJECT,
    icon: "web"
  },
  {
    title: "Statistics",
    path: "/panel/statistics",
    perform: navbar.STATISTICS,
    icon: "statistics"
  },
  {
    title: "Archive",
    path: "/panel/archive",
    perform: navbar.STATISTICS,
    icon: "database"
  },
  {
    title: "Feedbacks",
    path: "/panel/feedbacks",
    perform: navbar.FEEDBACKS,
    icon: "mail"
  },
  {
    title: "Card verification",
    path: "/panel/card-verification",
    perform: navbar.CARD_VERIFICATION,
    icon: "web"
  },
  {
    title: "Tariffs and subscriptions settings",
    path: "/panel/tariffs-and-subscriptions-settings",
    perform: navbar.ADMIN_TARIFFS_AND_SUBSCRIPTIONS,
    icon: "shopping-bag"
  },
  {
    title: "Payment settings",
    path: "/panel/payment-setting",
    perform: navbar.PAYMENT_SETTINGS,
    icon: "dollar"
  },
  {
    title: "Logs",
    path: "/panel/logs",
    perform: navbar.LOGS,
    icon: "history"
  }
];

const ownerRoutes = [
  {
    title: "Chats",
    path: "/panel/chats",
    perform: navbar.CHAT,
    icon: "chat"
  },
  {
    title: "Team chats",
    path: "/panel/corporate-chats",
    perform: navbar.CORPORATE_CHAT,
    icon: "communication"
  },
  {
    title: "Projects",
    path: "/panel/project",
    perform: navbar.PROJECT,
    icon: "web"
  },
  {
    title: "Managers",
    path: "/panel/users",
    perform: navbar.MANAGERS,
    icon: "users"
  },
  {
    title: "Statistics",
    path: "/panel/statistics",
    perform: navbar.STATISTICS,
    icon: "statistics"
  },
  {
    title: "Archive",
    path: "/panel/archive",
    perform: navbar.STATISTICS,
    icon: "database"
  },
  {
    title: "My tariff and subscription",
    path: "/panel/my-tariff-and-subscription",
    perform: navbar.OWNER_TARIFFS_AND_SUBSCRIPTIONS,
    icon: "shopping-bag"
  },
  {
    title: "Card verification",
    path: "/panel/card-verification",
    perform: navbar.CARD_VERIFICATION,
    icon: "web"
  }
];

const managerRoutes = [
  {
    title: "Chats",
    path: "/panel/chats",
    perform: navbar.CHAT,
    icon: "chat"
  },
  {
    title: "Team chats",
    path: "/panel/corporate-chats",
    perform: navbar.CORPORATE_CHAT,
    icon: "communication"
  },
  {
    title: "Statistics",
    path: "/panel/statistics",
    perform: navbar.STATISTICS,
    icon: "statistics"
  }
];

export { userRoutes, ownerRoutes, managerRoutes, adminRoutes };