import React, { useContext, useMemo } from "react";
import SidebarItem from "./SidebarItem";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../App";
import { generateSidebarRouter } from "./customSidebarFunck";

const SidebarLinkList = ({ role, handleChange, visible }) => {
  const { isCreatedSubAndProject } = useContext(AuthContext);
  const sidebarRoutesMemo = useMemo(() => generateSidebarRouter(isCreatedSubAndProject, role), [isCreatedSubAndProject, role]);

  return (
    <React.Fragment>
      {sidebarRoutesMemo.map((rout) => {
        return (
          <SidebarItem
            disabled={rout?.disabled}
            key={rout.title}
            role={role}
            perform={rout.perform}
            as={!rout?.disabled ? NavLink : "span"}
            to={!rout?.disabled ? rout.path : ""}
            icon={rout.icon}
            linkTitle={rout.title}
            handleChange={handleChange}
            visible={visible}
          />
        );
      })}
    </React.Fragment>
  );
};

export default SidebarLinkList;