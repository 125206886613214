import React, { useContext } from "react";
import { AuthContext } from "../../App";
import Can from "../../components/elements/can/Can";
import { logs } from "../../rbac-consts";
import LogsWrapper from "../../components/logs/admin/LogsWrapper";
import { roles } from "../../utils/consts";
import { Redirect } from "react-router-dom";

const LogsPage = () => {
  const { user } = useContext(AuthContext);

  if (user?.roles.toString() !== roles.ADMIN) {
    return <Redirect to="/page-not-found" />;
  }

  return (
    <Can
      role={user?.roles}
      perform={logs.ADMIN}
      yes={() => (
        <LogsWrapper />
      )}
    />
  );
};

export default LogsPage;