import React from "react";
import { css } from "@emotion/react";
import { MoonLoader, PuffLoader } from "react-spinners";

const Spinner = ({ size, color, display, margin, type }) => {
  const override = css`
    display: ${display};
    margin: ${margin || "15px auto"};
  `;

  switch (type) {
    case "moonLoader" :
      return <MoonLoader
        css={override}
        size={size}
        color={color}
        loading={true}
      />;
    default :
      return <PuffLoader
        css={override}
        size={size}
        color={color}
        loading={true}
      />;
  }
};

export default Spinner;