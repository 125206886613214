import styled from "styled-components";

const inputStyleTemplate = (theme) => {
  return `
    width: 100%;
    padding: 10px 15px;
    color: ${theme.bodyColorText};
    font-size: 14px;
    font-family: inherit;
    border: 1px solid ${theme.input.borderColor};
    border-radius: 7px;
    background-color: transparent;
    -webkit-appearance: none;
    transition: all .3s ease;
  
    &:focus, &:active {
      border: 1px solid ${theme.main};
      background: #FAF7FF;
      border: 1px solid #9B68E4;
    }
    
    &::placeholder {
      opacity: 0.9;
    }
    
    &:read-only {
      color: rgb(0 0 0 / 75%);
    }
    
    &:read-only:focus{
      box-shadow: none;
      background-color: transparent;
    }
  `;
};

export const StyledInputGroup = styled.div`
  ${({ mb }) => mb ? `margin-bottom: ${mb}px` : "margin-bottom: 15px"};
  display: flex;
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "100%"};
  flex-direction: column;
  ${({ type }) => type === "hidden" && `
    height: 0;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
  `};
`;

export const StyledLabel = styled.label`
  padding-bottom: 3px;
  color: ${({ theme }) => theme.input.labelColor};
  font-size: 14px;
  text-align: left;
  display: inline-flex;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const StyledTextarea = styled.textarea`
  height: 130px;
  resize: none;
  border: none;
  outline: none;
  ${({ theme }) => inputStyleTemplate(theme)}
  max-width: ${({ maxInputWidth }) => `${maxInputWidth}px` || "100%"};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledInput = styled.input`
  ${({ theme }) => inputStyleTemplate(theme)}
  max-width: ${({ maxInputWidth }) => `${maxInputWidth}px` || "100%"};
`;

export const StyledDelayInput = styled.div`
  input {
    max-width: ${({ maxInputWidth }) => `${maxInputWidth}px` || "100%"};
    font-family: inherit;
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledMaskInput = styled.div`
  input {
    max-width: ${({ maxInputWidth }) => `${maxInputWidth}px` || "100%"};
    font-family: inherit;
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  ${({ error }) => error && `input, textarea {
      color: #f44336;
      border: 1px solid #f44336;
      box-shadow: 0 1px 4px rgb(219 36 36 / 50%);
      &:focus, &:active {
        border: 1px solid #f44336;
        box-shadow: 0 5px 10px rgb(219 36 36 / 40%)
      }
    }`
  };
`;

export const StyledPasswordEye = styled.span`
  margin: 0;
  padding: 0;
  color: #656565;
  font-size: 20px;
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  opacity: 0.55;

  &:hover {
    opacity: 1;
  }
`;

export const StyledError = styled.div`
  padding-top: 3px;
  color: #f44336;
  font-size: 12px;
  text-align: left;
  word-break: break-word;
`;

export const StyledPrompt = styled.div`
  padding-top: 3px;
  color: #CFCFCF;
  font-size: 12px;
  text-align: left;
`;

export const StyledSkeletonInput = styled.div`
  width: 100%;
  height: 49px;
  padding: 10px 12px;
  background-color: transparent;
  border: 1px solid #9DA6B6;
  border-radius: 5px;

  & > div {
    margin: 0;
  }
`;