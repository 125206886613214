import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import eventBus from "../../utils/eventBus";
import {
  browsers,
  mercureTimeOut,
  mercureUrl,
  responseStatus,
  standardErrorsWay,
  subscriptionsSettings,
  userChanges
} from "../../utils/consts";
import { getMercureTopics } from "../../utils/getMercureTopics";

import Logo from "../elements/logo/Logo";
import Navigation from "../elements/navigation/Navigation";
import SidebarContainer from "../elements/sidebar/SidebarContainer";
import SubDaysToEnd from "./SubDaysToEnd";

import { closableNotification } from "../notification/ClosableNotification";
import { AuthContext } from "../../App";

import { StyledHeaderContainer, StyledHeaderWrapper } from "./styledHeader";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import { parseAxiosError } from "../../utils/response";

const Header = ({ subscriptionDaysToEnd, getSubscriptionEndDate, inViewport, userInfo: fetchUserInfo }) => {

  const { user, authenticated } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(["mercureAuthorization"]);
  const [showSubscriptionDaysToEnd, setShowSubscriptionDaysToEnd] = useState(false);
  const [userInfo, setUserInfo] = useState(fetchUserInfo);

  const minDaysSubscriptionExpire = 7;

  const examinationShowSubscriptionDaysToEnd = (subscriptionDaysToEnd && subscriptionDaysToEnd !==
      subscriptionsSettings.IS_UNLIMITED && subscriptionDaysToEnd < 7) &&
    showSubscriptionDaysToEnd;

  const fetchUser = () => {
    if (user && !fetchUserInfo) {
      axios.get("/api/get-sidebar-info/" + user?.userId, userAuthenticationConfig()).then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setUserInfo(response.data);
        }
      }).catch(error => {
        parseAxiosError(eval(standardErrorsWay));
      });
    }
  };

  let esUserChanges = null;
  let mercureUserChangesTime = null;

  const mercureUserChanges = () => {
    clearTimeout(mercureUserChangesTime);

    mercureUserChangesTime = setTimeout(() => {
      esUserChanges.close();
      esUserChanges = mercureUserChanges();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("user-changes", [{ id: user.userId }]),
      { path: "/" });

    esUserChanges = new EventSource(mercureUrl, { withCredentials: true });

    esUserChanges.onmessage = (event) => {
      let dataMercure = JSON.parse(event.data);

      if (dataMercure.hasOwnProperty("action") && dataMercure.action === userChanges.LOGOUT) {
        localStorage.removeItem("token");
        eventBus.dispatch("logout");
        closableNotification(dataMercure.message, "success");
      }
    };

    esUserChanges.onerror = (event) => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esUserChanges = mercureUserChanges();
    };

    return esUserChanges;
  };

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    esUserChanges = mercureUserChanges();

    return () => {
      esUserChanges.close();
      clearTimeout(mercureUserChangesTime);
    };

  }, [authenticated]);

  let esUpdateSidebarBalance = null;
  let mercureUpdateSidebarBalanceTime = null;

  const mercureUpdateSidebarBalance = () => {
    clearTimeout(mercureUpdateSidebarBalanceTime);

    mercureUpdateSidebarBalanceTime = setTimeout(() => {
      esUpdateSidebarBalance.close();
      esUpdateSidebarBalance = mercureUpdateSidebarBalance();
    }, mercureTimeOut);

    setCookie("mercureAuthorization", getMercureTopics("update-sidebar-balance", [{ id: user.userId }]),
      { path: "/" });

    esUpdateSidebarBalance = new EventSource(mercureUrl, { withCredentials: true });

    esUpdateSidebarBalance.onmessage = (event) => {
      fetchUser();
      getSubscriptionEndDate();
    };

    esUpdateSidebarBalance.onerror = (event) => {
      let agent = navigator.userAgent;

      if (!agent.includes(browsers.FIREFOX)) {
        return;
      }

      esUpdateSidebarBalance = mercureUpdateSidebarBalance();
    };

    return esUpdateSidebarBalance;
  };

  useEffect(() => {
    if (!authenticated || !user) {
      return;
    }

    esUpdateSidebarBalance = mercureUpdateSidebarBalance();

    return () => {
      esUpdateSidebarBalance.close();
      clearTimeout(mercureUpdateSidebarBalanceTime);
    };

  }, [authenticated]);

  useEffect(() => {
    if (subscriptionDaysToEnd && subscriptionDaysToEnd !== subscriptionsSettings.IS_UNLIMITED && subscriptionDaysToEnd <
      minDaysSubscriptionExpire) {
      setShowSubscriptionDaysToEnd(true);
    }
  }, [subscriptionDaysToEnd]);

  const handleCancelShowSubEndDay = () => {
    setShowSubscriptionDaysToEnd(false);
  };

  return (
    <StyledHeaderWrapper
      displayViewport={!inViewport}
    >
      <StyledHeaderContainer wrapper="content">
        <div className="header-left">
          <Logo />
        </div>
        <Navigation
          fontSize="14"
          top={true}
        />
        <div className="header-right">
          <SidebarContainer
            fetchUser={fetchUser}
            userInfo={userInfo}
          />
        </div>
        {/*Це так треба для зручності при редизайні проекту*/}

        {/*<div style={{position: "absolute", bottom: "-150px", right: 0}}>*/}
        {/*  <ThemeToggler />*/}
        {/*</div>*/}
      </StyledHeaderContainer>
      {examinationShowSubscriptionDaysToEnd &&
        <SubDaysToEnd
          subscriptionDaysToEnd={subscriptionDaysToEnd}
          handleCancelShowSubEndDay={handleCancelShowSubEndDay}
        />
      }
    </StyledHeaderWrapper>
  );
};

export default Header;