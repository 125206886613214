import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import {
  StyledMenuList,
  StyledMobileNavigation,
  StyledNavigationContent,
  StyledNavigationWrapper
} from "./styledNavigation";
import { StyledDecorationElementHomePage } from "../../home/styledHomePage";
import eventBus from "../../../utils/eventBus";
import { AuthContext } from "../../../App";
import { StyledButton } from "../../styles/styledButton";
import { HashLink } from "react-router-hash-link";

const Navigation = ({ fontSize, top, inViewport }) => {
  const [show, setShow] = useState(false);

  const { authenticated, setSubscriptionDaysToEnd } = useContext(AuthContext);

  const blockedBody = () => {
    if (show) {
      return document.body.style.cssText = `
        touch-action: none;
        overflow: hidden;
      `;
    }

    document.body.removeAttribute("style");
  };

  useEffect(() => {
    blockedBody();
  }, [show]);

  const toggleMenu = () => {
    setShow(!show);
  };

  const closeMenu = () => {
    setShow(false);
  };

  const handleExaminationAuthenticated = (authenticated) => {
    if (authenticated) {
      return (
        <li className="sidebar-logout">
          <NavLink
            as="button"
            to=""
            onClick={() => {
              closeMenu();
              eventBus.dispatch("logout");
              setSubscriptionDaysToEnd(null);
            }}
          >
            <StyledDecorationElementHomePage
              color="#A9A9A9"
              className="nav-icon icon-sign-out"
            />
            <StyledDecorationElementHomePage
              mt="4"
              className="nav-title"
              lineHeight="0"
            >
              Log out
            </StyledDecorationElementHomePage>
          </NavLink>
        </li>
      );
    } else {
      return (
        <div className="not-authenticated-actions">
          <li>
            <div>
              <StyledButton
                color="white"
                border="true"
                size="14"
                as={NavLink}
                onClick={closeMenu}
                to="/login"
                className="login-button"
              >
                <StyledDecorationElementHomePage
                  color="#000000"
                  className="nav-icon icon-lock"
                />
                <span className="login-button__text">Login</span>
              </StyledButton>
            </div>
          </li>
          <li>
            <div>
              <StyledButton
                color="white"
                size="14"
                onClick={closeMenu}
                as={NavLink}
                to="/registration"
                className="registration-button"
                border="true"
              >
                Sign up free
              </StyledButton>
            </div>
          </li>
        </div>
      );
    }
  };

  return (
    <StyledNavigationWrapper>
      <StyledMobileNavigation
        show={show}
        onClick={toggleMenu}
      >
        <div className="navbar-top" />
        <div className="navbar-middle" />
        <div className="navbar-bottom" />
      </StyledMobileNavigation>
      <StyledNavigationContent
        show={show}
        top={top}
      >
        <StyledMenuList
          show={show}
          fontSize={fontSize}
          inViewport={inViewport}
        >
          <li>
            <NavLink
              to="/"
              onClick={closeMenu}
            >
              <StyledDecorationElementHomePage
                color="#A9A9A9"
                className="nav-icon icon-home"
              />
              <StyledDecorationElementHomePage
                mt="4"
                className="nav-title"
                lineHeight="15"
              >
                Home
              </StyledDecorationElementHomePage>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tariff-and-subscription"
              onClick={closeMenu}
            >
              <StyledDecorationElementHomePage
                color="#A9A9A9"
                className="nav-icon icon-shopping-bag"
              />
              <StyledDecorationElementHomePage
                mt="4"
                className="nav-title"
                lineHeight="15"
              >
                Subscription
              </StyledDecorationElementHomePage>
            </NavLink>
          </li>
          <li>
            <HashLink
              to="/#contact"
              onClick={closeMenu}
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            >
              <StyledDecorationElementHomePage
                color="#A9A9A9"
                className="nav-icon icon-phone"
              />
              <StyledDecorationElementHomePage
                mt="4"
                className="nav-title"
                lineHeight="15"
              >
                Contacts
              </StyledDecorationElementHomePage>
            </HashLink>
          </li>
          {handleExaminationAuthenticated(authenticated)}
        </StyledMenuList>
      </StyledNavigationContent>
    </StyledNavigationWrapper>
  );
};

export default Navigation;